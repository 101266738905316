import {kAboutNavigation} from "../../../constants/navigation";
import AboutTabletTabBarItem from "../../items/aboutScreen/AboutTabletTabBarItem";

function AboutTabletTabBar({selectedTab, setSelectedTab}) {
    // Used in AboutScreen to display sections tab bar in desktop mode

    // BUILD
    return (
        <div className={"leading-none w-full border-b border-greyLight7 hidden sm:flex lg:hidden mt-32"}>
            {kAboutNavigation.map((item, index) => (
                <AboutTabletTabBarItem
                    key={index}
                    item={item}
                    isSelected={selectedTab === index}
                    setSelectedTab={() => setSelectedTab(index)}/>
            ))}
        </div>
    );
}

// EXPORT
export default AboutTabletTabBar