import {SystemPrimaryCustomButton, SystemSecondaryCustomButton} from "../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../constants/styles";
import {isArrayEmpty} from "../../../services/Validator";
import {CircularProgress} from "@mui/material";

function ParameterMobileBottomBar({selectedLevelAs,
                                  fetchFlashQuestions,
                                  isFetchingLoading,
                                  goToPreviousStep}) {
    // Used in GradeStepScreen to display action buttons
    // in mobile mode

    // BUILD
    return (
        <div className={"w-full h-[64px] min-h-[64px] flex justify-center bg-background p-12 space-x-12"}>
            <SystemSecondaryCustomButton
                label={"Précédent"}
                isDisabled={false}
                isLoading={false}
                isFullWidth={true}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                onButtonClick={isFetchingLoading ? null : () => goToPreviousStep()}
                extraClass={"px-0"}/>
            <SystemPrimaryCustomButton
                label={"Lancer le flash"}
                isDisabled={isArrayEmpty(selectedLevelAs)}
                isLoading={isFetchingLoading}
                loaderComponent={<CircularProgress size={"28px"}
                                                   style={{'color' : 'white'}} />}
                isFullWidth={true}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                onButtonClick={() => fetchFlashQuestions()}
                extraClass={"px-0"} />
        </div>
    );
}

// EXPORT
export default ParameterMobileBottomBar