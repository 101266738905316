import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routes} from "./config/routes";
import FlashScreen from "./screens/FlashScreen";
import AboutScreen from "./screens/AboutScreen";
import LegalsScreen from "./screens/LegalsScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import ErrorRoute from "./routes/ErrorRoute";
import PreviewQuestionScreen from "./screens/flashScreens/PreviewQuestionScreen";

// Router
const router = createBrowserRouter([
    {
        path: routes.landing,
        element: <App/>,
        errorElement: <ErrorRoute />,
    },
    {
        path: routes.flash,
        element: <FlashScreen />
    },
    {
        path: routes.about,
        element: <AboutScreen />
    },
    {
        path: routes.legals,
        element: <LegalsScreen />
    },
    {
        path: routes.privacy,
        element: <PrivacyScreen />
    },
    {
        path: routes.preview,
        element: <PreviewQuestionScreen />
    }
]);

// DOM
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
