import LawMobileSectionCoreText from "../lawScreens/LawMobileSectionCoreText";
import LawMobileBulletSectionText from "../lawScreens/LawMobileBulletSectionText";
import {kLegalTexts} from "../../../constants/texts";

function LegalsMobileHostText() {
    // Used in

    // BUILD
    return (
        <div className={"flex flex-col items-start justify-start space-y-6"}>
            <LawMobileSectionCoreText text={kLegalTexts.hostOne} />
            <LawMobileBulletSectionText text={kLegalTexts.hostTwo} />
            <LawMobileBulletSectionText text={kLegalTexts.hostThree} />
            <LawMobileBulletSectionText text={kLegalTexts.hostFour} />
        </div>
    );
}

// EXPORT
export default LegalsMobileHostText