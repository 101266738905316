import {isNullOrUndefined} from "../../../services/Validator";

function LawContentMobileLayout({children}) {
    // Used in both Legals and Privacy screens to frame
    // content in mobile mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto sm:hidden w-full grow flex flex-col space-y-24 items-center justify-start bg-backgroundLight3 animate-appear px-24 py-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default LawContentMobileLayout