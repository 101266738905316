import {isNullOrUndefined} from "../../../services/Validator";

function AboutDesktopTabletItemLayout({children}) {
    // Used in AboutScreen sub-screens to frame
    // text made of both a title and a text

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full flex flex-col space-y-8"}>
            {children}
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletItemLayout