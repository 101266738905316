import {isNullOrUndefined} from "../../../services/Validator";

function AboutDesktopTabletSectionCoreText({text}) {
    // Used in AboutScreen section to display basic text
    // in tablet / desktop modes

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"whitespace-pre-line"}>
            <span className={"text-[18px] font-medium text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletSectionCoreText