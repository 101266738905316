import LawMobileSectionTitleText from "../../texts/lawScreens/LawMobileSectionTitleText";
import LawMobileSectionCoreText from "../../texts/lawScreens/LawMobileSectionCoreText";
import LawMobileItemLayout from "../../layouts/lawScreens/LawMobileItemLayout";
import PrivacyMobileCollectedDataText from "../../texts/privacyScreen/PrivacyMobileCollectedDataText";
import PrivacyMobileContactText from "../../texts/privacyScreen/PrivacyMobileContactText";
import {kPrivacyTexts} from "../../../constants/texts";

function PrivacyMobileContent() {
    // Used in PrivacyScreen to display content
    // in mobile mode

    // BUILD
    return (
        <div className="w-full flex flex-col space-y-24">
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={1} title={"Introduction"} />
                <LawMobileSectionCoreText text={kPrivacyTexts.intro} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={2} title={"Données collectées"} />
                <PrivacyMobileCollectedDataText />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={3} title={"Utilisation des données"} />
                <LawMobileSectionCoreText text={kPrivacyTexts.dataUsage} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={4} title={"Sécurité des données"} />
                <LawMobileSectionCoreText text={kPrivacyTexts.dataSafety} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={5} title={"Liens vers des sites tiers"} />
                <LawMobileSectionCoreText text={kPrivacyTexts.externalSites} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={6} title={"Modification de cette politique de confidentialité"} />
                <LawMobileSectionCoreText text={kPrivacyTexts.documentUpdate} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={7} title={"Contactez-nous"} />
                <PrivacyMobileContactText />
            </LawMobileItemLayout>
        </div>
    );
}

// EXPORT
export default PrivacyMobileContent