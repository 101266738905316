import {useState} from "react";
import {isNullOrUndefined} from "../../services/Validator";
import {
    computePreferredAxis,
    computeQuestionPictureSize
} from "../../controllers/questionsStepScreen/QuestionPictureController";
import {kSystemIcons} from "../../constants/icons";

function SystemQuestionPicture({pictureUrl, pictureRatio, isForMobile, openZoom}) {
    // Used in QuestionsCard to display
    // question related image

    // PROPERTIES
    const [hasError, setHasError] = useState(false);
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    if (hasError || isNullOrUndefined(pictureUrl))
        return null
    return (
        <div className={`relative ${!isForMobile ? 'cursor-pointer' : 'cursor-auto'} overflow-hidden rounded-[8px] ${computeQuestionPictureSize(pictureRatio, isForMobile)}`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={openZoom} >
            <img src={pictureUrl}
                 alt="Question"
                 className={`object-cover ${computePreferredAxis(pictureRatio)}`}
                 onError={() => setHasError(true)}
                 onLoad={() => setHasError(false)} />
            <div className={`animate-appearFast ${(isHovered && !isForMobile) ? 'absolute bottom-0 left-0 w-full h-[32px] bg-gradient-to-t from-backgroundLight1/80 to-transparent opacity-100' : 'opacity-0'}`}></div>
            <div className={`animate-appearFast ${(isHovered && !isForMobile) ? "flex justify-center items-center absolute bottom-[2px] right-[4px]" : "hidden"}`}>
                <i className={`material-icons text-[24px] text-white`}>
                    {kSystemIcons.zoomIn}
                </i>
            </div>
        </div>
    );
}

// EXPORT
export default SystemQuestionPicture