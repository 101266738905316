import {kSystemIcons} from "../../../constants/icons";
import {kVoidTexts} from "../../../constants/texts";

function ParameterLevelAsVoid() {
    // Used in ParameterLevelAsList to display empty
    // LevelAs list

    // BUILD
    return (
        <div className={"w-full flex flex-col justify-center items-center px-24"}>
            <i className={`material-icons text-[24px] lg:text-[32px] text-greyLight7`}>
                {kSystemIcons.support}
            </i>
            <span className={"text-center font-medium text-[14px] text-greyLight7 mt-8"}>
                {kVoidTexts.levelAsList}
            </span>
        </div>
    );
}

// EXPORT
export default ParameterLevelAsVoid