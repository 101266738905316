import {kAboutMethodTexts} from "../../../constants/texts";

function AboutMobileMethodSolutionIntroText() {
    // Used in AboutMobileMethodSection to display solution enriched text

    // BUILD
    return (
        <div className={"whitespace-pre-line text-[16px] font-medium"}>
            <span className={"text-white"}>
                {kAboutMethodTexts.solutionIntroOne}
            </span>
            <span className={`text-white whitespace-nowrap`}>
                {kAboutMethodTexts.solutionIntroTwo}
            </span>
        </div>
    );
}

// EXPORT
export default AboutMobileMethodSolutionIntroText