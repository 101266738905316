
function SystemCustomCheckBox({isChecked}) {
    // Used through the app to display a checkbox

    // BUILD
    return (
        <div className={`w-[14px] min-w-[14px] h-[14px] min-h-[14px] border-1 ${isChecked ? "border-primaryLight5 bg-primaryLight5" : "border-greyLight9"} rounded-[2px] mt-5 flex justify-center items-center`}>
            {isChecked
                ? <i className={`material-icons text-[12px] text-primaryDark7`}>
                    {"check"}
                  </i>
                : null
            }
        </div>
    );
}

// EXPORT
export default SystemCustomCheckBox