import LawDesktopTabletItemLayout from "../../layouts/lawScreens/LawDesktopTabletItemLayout";
import LawDesktopTabletSectionTitleText from "../../texts/lawScreens/LawDesktopTabletSectionTitleText";
import LawDesktopTabletSectionCoreText from "../../texts/lawScreens/LawDesktopTabletSectionCoreText";
import PrivacyDesktopTabletContactText from "../../texts/privacyScreen/PrivacyDesktopTabletContactText";
import {kPrivacyTexts} from "../../../constants/texts";

function PrivacyDesktopTabletContent() {
    // Used in

    // BUILD
    return (
        <div className="w-full flex flex-col space-y-24">
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={1} title={"Introduction"} />
                <LawDesktopTabletSectionCoreText text={kPrivacyTexts.intro} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={2} title={"Données collectées"} />
                <PrivacyDesktopTabletContactText />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={3} title={"Utilisation des données"} />
                <LawDesktopTabletSectionCoreText text={kPrivacyTexts.dataUsage} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={4} title={"Sécurité des données"} />
                <LawDesktopTabletSectionCoreText text={kPrivacyTexts.dataSafety} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={5} title={"Liens vers des sites tiers"} />
                <LawDesktopTabletSectionCoreText text={kPrivacyTexts.externalSites} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={6} title={"Modification de cette politique de confidentialité"} />
                <LawDesktopTabletSectionCoreText text={kPrivacyTexts.documentUpdate} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={7} title={"Contactez-nous"} />
                <PrivacyDesktopTabletContactText />
            </LawDesktopTabletItemLayout>
        </div>
    );
}

// EXPORT
export default PrivacyDesktopTabletContent