import {isNullOrUndefined} from "../../../services/Validator";

function LawDesktopTabletLayout({children}) {
    // Used in both Legals and Privacy screens to frame
    // content in both tablet and desktop modes

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto hidden bg-backgroundLight3 w-full grow sm:flex sm:flex-col items-center justify-start"}>
            {children}
        </div>
    );
}

// EXPORT
export default LawDesktopTabletLayout