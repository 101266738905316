import {isNullOrUndefined} from "../../../services/Validator";

function LawDesktopTabletBulletSectionText({text}) {
    // Used in AboutScreen to show bullet list text

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"text-[18px] font-medium text-white flex space-x-12 items-start justify-start"}>
            <div className={"h-[10px] min-h-[10px] w-[10px] min-w-[10px] bg-white rounded-full mt-10"}></div>
            <span>{text}</span>
        </div>
    );
}

// EXPORT
export default LawDesktopTabletBulletSectionText