import {isNullOrUndefined} from "../../../services/Validator";

function AboutMobileSectionTopTitleText({title}) {
    // Used in about sub screens to display
    // under section image main title text

    // BUILD
    if(isNullOrUndefined(title))
        return null
    return (
        <div className={"w-full text-center font-bold text-white"}>
            <span className={"text-[18px]"}>
                {title}
            </span>
        </div>
    );
}

// EXPORT
export default AboutMobileSectionTopTitleText