import {useState} from "react";
import {isNullOrUndefined} from "../../../services/Validator";
import {renderSubthemeStyle, renderSubthemeThemeStyle} from "../../../controllers/SubthemeItemController";
import {capitalizeFirst} from "../../../helpers/StringHelper";
import {get_nested} from "../../../services/JsonHandler";
import SystemIcon from "../../icons/SystemIcon";

function ParameterMobileSubthemeItem({subtheme, selectSubtheme, selectedSubtheme}) {
    // Used in LevelDesktopSchoolLevelsList to display one single
    // level card

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)
    const subthemeName = get_nested(['name'], subtheme, null)
    const subthemeThemeName = get_nested(['subthemeTheme', 'name'], subtheme, null)

    // BUILD
    if (isNullOrUndefined(subtheme))
        return null
    return (
        <div className={`w-full flex justify-between items-center cursor-pointer px-12 py-8 border-b-1 border-backgroundLight5`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={() => selectSubtheme(subtheme, true)}>
            <div className={"flex flex-col font-medium"}>
                <span className={`text-[16px] ${renderSubthemeStyle(subtheme, selectedSubtheme, isHovered)}`}>
                    {capitalizeFirst(subthemeName)}
                </span>
                <span className={`text-[12px] ${renderSubthemeThemeStyle(subtheme, selectedSubtheme, isHovered)}`}>
                    {capitalizeFirst(subthemeThemeName)}
                </span>
            </div>
            <SystemIcon icon={"chevron_right"}
                        isHovered={isHovered} />
        </div>
    );
}

// EXPORT
export default ParameterMobileSubthemeItem