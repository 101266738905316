import {isNullOrUndefined} from "../../../services/Validator";

function LawDesktopTabletSectionCoreText({text}) {
    // Used in Law screens to display single section
    // text under section title

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"whitespace-pre-line"}>
            <span className={"text-[18px] font-medium text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default LawDesktopTabletSectionCoreText