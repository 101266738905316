import {isNullOrUndefined} from "../../../services/Validator";

function AboutDesktopTabletBulletSectionText({text}) {
    // Used in

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"text-[18px] font-medium text-white flex space-x-12 items-start justify-start"}>
            <div className={"h-[12px] min-h-[12px] w-[12px] min-w-[12px] bg-white rounded-full mt-10"}></div>
            <span className={"text-white"}>{text}</span>
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletBulletSectionText