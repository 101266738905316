import {isNullOrUndefined} from "../../services/Validator";

function SystemFlashStepTitleText({text}) {
    // Used through the app to display top step progress
    // bar title text in desktop mode

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"w-full flex justify-center leading-tight"}>
            <span className={"text-[16px] sm:text-[18px] lg:text-[20px] text-center font-medium text-greyLight7"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default SystemFlashStepTitleText