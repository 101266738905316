import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../../../services/Validator";
import ParameterUnselectedGrade from "./ParameterUnselectedGrade";
import ParameterSubthemesLoader from "../../loaders/parameterStepScreen/ParameterSubthemesLoader";
import ParameterSubthemesError from "../../errors/parameterScreen/ParameterSubthemesError";
import ParameterSubthemesContentHeader from "./ParameterSubthemesContentHeader";
import ParameterSubthemesList from "../../lists/parameterStepScreen/ParameterSubthemesList";
import ParameterLevelAsList from "../../lists/parameterStepScreen/ParameterLevelAsList";
import ParameterSubthemesVoid from "../../voids/parameterStepScreen/ParameterSubthemesVoid";

function ParameterSubthemesContent({selectedSubtheme,
                                           selectedGrade,
                                           isSubthemesLoading,
                                           hasSubthemesError,
                                           subthemes,
                                           levelAs,
                                           toggleLevelA,
                                           selectSubtheme,
                                           selectedLevelAs}) {
    // Used in ParameterContent to display Subthemes and LevelAs
    // related components in desktop and tablet modes

    // PROPERTIES
    const subthemesCount = subthemes?.length
    const levelAsCount = levelAs?.length
    const selectedLevelAsCount = selectedLevelAs?.length

    // BUILD
    if (hasSubthemesError)
        return <ParameterSubthemesError />
    if (isSubthemesLoading)
        return <ParameterSubthemesLoader />
    if (isNullOrUndefined(selectedGrade))
        return <ParameterUnselectedGrade />
    if (isArrayNullUndefinedOrEmpty(subthemes))
        return <ParameterSubthemesVoid />
    return (
        <div className={"overflow-auto animate-appear w-full h-full flex flex-col justify-start shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] mt-24"}>
            <ParameterSubthemesContentHeader subthemesCount={subthemesCount}
                                             levelAsCount={levelAsCount}
                                             selectedLevelAsCount={selectedLevelAsCount} />
            <div className={"overflow-auto w-full h-full flex bg-backgroundLight3 border-1 border-backgroundLight5 rounded-b-[16px]"}>
                <ParameterSubthemesList subthemes={subthemes}
                                        selectSubtheme={selectSubtheme}
                                        selectedSubtheme={selectedSubtheme} />
                <ParameterLevelAsList levelAs={levelAs}
                                      selectedLevelAs={selectedLevelAs}
                                      toggleLevelA={toggleLevelA} />
            </div>
        </div>
    );
}

// EXPORT
export default ParameterSubthemesContent