import {kFlashTexts} from "../../constants/texts";
import {kSystemIcons} from "../../constants/icons";

function SystemDesktopTabletLeaveFlashButton({onLeaveFlashPressed}) {
    // Used through the app to display leave flash text button
    // in screens top bar for desktop / tablet modes

    // BUILD
    return (
        <div className={"cursor-pointer whitespace-nowrap flex space-x-6 items-center cursor-pointer text-greyLight7 hover:text-greyLight11"}
             onClick={onLeaveFlashPressed}>
            <i className="material-icons text-[20px]">{kSystemIcons.logout}</i>
            <span className={"text-[14px] lg:text-[16px] font-medium"}>
                {kFlashTexts.leaveFlash}
            </span>
        </div>
    );
}

// EXPORT
export default SystemDesktopTabletLeaveFlashButton