import LawDesktopTabletSectionCoreText from "../lawScreens/LawDesktopTabletSectionCoreText";
import LawDesktopTabletBulletSectionText from "../lawScreens/LawDesktopTabletBulletSectionText";
import {kLegalTexts} from "../../../constants/texts";

function LegalsDesktopTabletInfoText() {
    // Used in

    // BUILD
    return (
        <div className={"flex flex-col items-start justify-start space-y-8"}>
            <LawDesktopTabletSectionCoreText text={kLegalTexts.infoOne} />
            <LawDesktopTabletBulletSectionText text={kLegalTexts.infoTwo} />
            <LawDesktopTabletBulletSectionText text={kLegalTexts.infoThree} />
            <LawDesktopTabletBulletSectionText text={kLegalTexts.infoFour} />
        </div>
    );
}

// EXPORT
export default LegalsDesktopTabletInfoText