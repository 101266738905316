import {renderDateText} from "../../../controllers/LawLastUpdateTextController";
import {isNullOrUndefined} from "../../../services/Validator";

function LawDesktopTabletLastUpdateText({lastUpdateDate, isLegals}) {
    // Used in LawDesktopTabletTitle to display
    // text date update in desktop tablet mode

    // BUILD
    if (isNullOrUndefined(lastUpdateDate))
        return null
    return (
        <div className={"mt-12"}>
            <span className={"text-[14px] font-normal text-greyLight5"}>
                {renderDateText(lastUpdateDate, isLegals)}
            </span>
        </div>
    );
}

// EXPORT
export default LawDesktopTabletLastUpdateText