import {kFlashTexts} from "../../constants/texts";
import {kSystemIcons} from "../../constants/icons";

function SystemTabletFromFlashToHomeButton({goBackToHome}) {
    // Used through the app to display back to home text button
    // in desktop mode

    // BUILD
    return (
        <div className={"whitespace-nowrap flex space-x-6 items-center cursor-pointer text-greyLight7 hover:text-greyLight11"}
             onClick={() => goBackToHome()} >
            <i className="material-icons text-[20px]">{kSystemIcons.logout}</i>
            <span className={"text-[14px] font-medium"}>
                {kFlashTexts.backToHome}
            </span>
        </div>
    );
}

// EXPORT
export default SystemTabletFromFlashToHomeButton