import {useState} from "react";
import AboutDesktopTabBarItemText from "../../texts/aboutScreen/AboutDesktopTabBarItemText";


function AboutDesktopTabBarItem({item, isSelected, setSelectedTab}) {
    // Used in

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <div className={`sm:cursor-pointer px-2 h-[40px] w-full flex items-center justify-start ${isSelected ? "border-b-2 border-greyLight11" : ""}`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={setSelectedTab}>
            <AboutDesktopTabBarItemText text={item.title}
                                        isSelected={isSelected}
                                        isHovered={isHovered} />
        </div>
    );
}

// EXPORT
export default AboutDesktopTabBarItem