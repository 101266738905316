import {kAboutNavigation} from "../../../constants/navigation";
import SystemMobileTabBarItem from "../../items/SystemMobileTabBarItem";

function AboutMobileTabBar({selectedTab, setSelectedTab}) {
    // Used in AboutScreen to display tab bar view accessing
    // different about sections

    // BUILD
    return (
        <div className={"bg-backgroundLight3 w-full h-[48px] min-h-[48px] flex sm:hidden border-b border-greyLight7"}>
            {kAboutNavigation.map((item, index) => (
                <SystemMobileTabBarItem
                    key={index}
                    item={item}
                    isSelected={selectedTab === index}
                    setSelectedTab={() => setSelectedTab(index)}/>
            ))}
        </div>
    );
}

// EXPORT
export default AboutMobileTabBar