import {isNullOrUndefined} from "../../../services/Validator";
import {computeLevelAsCounterText} from "../../../controllers/parameterScreen/ParameterSelectedLevelAsCounterController";

function ParameterSelectedLevelAsCounter({count, screenWidth}) {
    // Used in ParameterMobileLevelAsHeader to display selected levelAs
    // counter in all modes

    // BUILD
    if (isNullOrUndefined(count) || isNullOrUndefined(screenWidth))
        return null
    return (
        <div className={"bg-primaryLight5 flex justify-center items-center px-6 py-0 sm:py-4 rounded-[4px]"}>
            <span className={"text-whiteDark8 font-semibold sm:font-medium text-[14px] sm:text-[14px]"}>
                {computeLevelAsCounterText(count, screenWidth)}
            </span>
        </div>
    );
}

// EXPORT
export default ParameterSelectedLevelAsCounter