import {isNullOrUndefined} from "../../../services/Validator";

function CorrectionTabletContentLayout({children}) {
    // Used in CorrectionStepScreen to frame content in
    // both desktop and tablet mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-[536px] md:w-[672px] grow hidden sm:flex sm:flex-col lg:hidden items-center justify-start pb-24 px-16 pt-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionTabletContentLayout