import {FlashLogoTextSize} from "../../../controllers/FlashLogoTextController";
import SystemFlashLogoImage from "../../images/SystemFlashLogoImage";
import SystemTabletFromFlashToHomeButton from "../../buttons/SystemTabletFlashToHomeButton";
import SystemTabletTopBarLayout from "../../layouts/SystemTabletTopBarLayout";

function ParameterTabletTopBar({goBackToHome}) {
    // Used in GradeStepScreen to display top bar
    // in tablet mode

    // BUILD
    return (
        <SystemTabletTopBarLayout>
            <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL} />
            <SystemTabletFromFlashToHomeButton goBackToHome={goBackToHome} />
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default ParameterTabletTopBar