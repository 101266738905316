import Modal from "@mui/material/Modal";
import {kButtonStyles, kModalStyles} from "../../constants/styles";
import SystemDialogClosingCrossIcon from "../icons/SystemDialogClosingCrossIcon";
import SystemModalTexts from "../texts/SystemModalTexts";
import {SystemPrimaryCustomButton, SystemSecondaryCustomButton} from "../buttons/SystemCustomButton";
import SystemDialogHeaderLayout from "../layouts/SystemDialogHeaderLayout";
import SystemDialogMainImage from "../images/SystemDialogMainImage";
import {kFlashTexts} from "../../constants/texts";

function SystemLeaveFlashDialog({open, handleClose, leaveFlash}) {
    // Used in QuestionsStepScreen to ask confirmation
    // about leaving current flash

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <div className={kModalStyles.dialogStyle}>
                <SystemDialogHeaderLayout>
                    <SystemDialogClosingCrossIcon onIconPress={() => handleClose()}/>
                    <SystemDialogMainImage imageUrl={"/images/behindCryingBear.png"}
                                           alt=""/>
                </SystemDialogHeaderLayout>
                <SystemModalTexts title={kFlashTexts.leaveCurrentFlashTitle}
                                  subtitle={kFlashTexts.leaveCurrentFlashSubtitle} />
                <div className="w-full flex space-x-16">
                    <SystemSecondaryCustomButton
                        label={"Annuler"}
                        isDisabled={false}
                        isLoading={false}
                        isFullWidth={true}
                        sizeStyle={kButtonStyles.mediumButtonStyle}
                        onButtonClick={() => handleClose()}
                        extraClass={"w-full"}/>
                    <SystemPrimaryCustomButton
                        label={"Quitter"}
                        isDisabled={false}
                        isLoading={false}
                        isFullWidth={true}
                        onButtonClick={leaveFlash}
                        sizeStyle={kButtonStyles.mediumButtonStyle}/>
                </div>
            </div>
        </Modal>
    )
}

// EXPORT
export default SystemLeaveFlashDialog