import SystemMailToButton from "../../buttons/SystemMailToButton";
import {kPrivacyTexts, kSystemTexts} from "../../../constants/texts";

function PrivacyDesktopTabletContactText() {
    // Used in PrivacyScreen to display contact text

    // BUILD
    return (
        <div className={"text-[18px] font-medium"}>
            <span className={"text-white"}>
                {kPrivacyTexts.contact}
            </span>
            <SystemMailToButton label={kSystemTexts.contactMailAddress}
                                mailTo={`mailto:${kSystemTexts.contactMailAddress}`} />
        </div>
    );
}

// EXPORT
export default PrivacyDesktopTabletContactText