import {kSystemIcons} from "../../../constants/icons";

function ParameterSubthemesError() {
    // Used in

    // BUILD
    return (
        <div className={"animate-appear w-full h-full flex flex-col justify-center items-center shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] mt-24"}>
            <i className={`material-icons text-[24px] lg:text-[32px] text-greyLight7`}>
                {kSystemIcons.error}
            </i>
            <span className={"font-normal text-[16px] lg:text-[18px] text-greyLight7"}>
                {"Une erreur est survenue, nous sommes désolés pour la gêne occasionnée"}
            </span>
        </div>
    );
}

// EXPORT
export default ParameterSubthemesError