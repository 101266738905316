import {useState} from "react";
import {kAboutMissionTexts} from "../../../constants/texts";

function AboutMobileMissionConclusionText({goToMethod}) {
    // Used in AboutMobileMissionSection to display conclusion

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <div className={"whitespace-pre-line text-[16px] font-medium"}>
            <span className={"text-white"}>
                {kAboutMissionTexts.outroOne}
            </span>
            <span className={`text-primaryLight1 cursor-pointer ${isHovered ? "underline" : ""}`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={goToMethod}>
                {kAboutMissionTexts.outroTwo}
            </span>
            <span className={"text-white"}>
                {kAboutMissionTexts.outroThree}
            </span>
        </div>
    );
}

// EXPORT
export default AboutMobileMissionConclusionText