import Modal from "@mui/material/Modal";
import {kButtonStyles, kModalStyles} from "../../../constants/styles";
import SystemDialogClosingCrossIcon from "../../icons/SystemDialogClosingCrossIcon";
import SystemModalTexts from "../../texts/SystemModalTexts";
import {SystemPrimaryCustomButton} from "../../buttons/SystemCustomButton";
import SystemDialogMainImage from "../../images/SystemDialogMainImage";
import SystemDialogHeaderLayout from "../../layouts/SystemDialogHeaderLayout";
import {computeFlashAppreciation} from "../../../controllers/FlashAppreciationController";
import {renderScoreEmoji} from "../../../controllers/ResultsScoreEmojiImageController";

function ResultsAppreciationDialog({open, handleClose, successRatio}) {
    // Used in ResultsScore to show total score related appreciation
    // in a modal

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <div className={kModalStyles.dialogStyle}>
                <SystemDialogHeaderLayout>
                    <SystemDialogClosingCrossIcon onIconPress={() => handleClose()} />
                    <SystemDialogMainImage imageUrl={renderScoreEmoji(successRatio)} />
                </SystemDialogHeaderLayout>
                <SystemModalTexts title={computeFlashAppreciation(successRatio)} />
                <SystemPrimaryCustomButton
                    label={"Retour"}
                    isDisabled={false}
                    isLoading={false}
                    isFullWidth={true}
                    onButtonClick={() => handleClose()}
                    sizeStyle={kButtonStyles.mediumButtonStyle}/>
            </div>
        </Modal>
    )
}

// EXPORT
export default ResultsAppreciationDialog