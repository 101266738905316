import Modal from "@mui/material/Modal";
import {Slide} from "@mui/material";
import CorrectionDesktopLayout from "../../components/layouts/correctionStepScreen/CorrectionDesktopLayout";
import CorrectionDesktopGhostTopBar from "../../components/bars/correctionStepScreen/CorrectionDesktopGhostTopBar";
import CorrectionTabletGhostTopBar from "../../components/bars/correctionStepScreen/CorrectionTabletGhostTopBar";
import SystemBottomSheetClosingIcon from "../../components/icons/SystemBottomSheetClosingIcon";
import CorrectionTabletLayout from "../../components/layouts/correctionStepScreen/CorrectionTabletLayout";
import CorrectionMobileGhostTopBar from "../../components/bars/correctionStepScreen/CorrectionMobileGhostTopBar";
import CorrectionMobileLayout from "../../components/layouts/correctionStepScreen/CorrectionMobileLayout";
import {ClosingIconSize} from "../../controllers/SystemBottomSheetClosingIconController";
import CorrectionDesktopContent from "../../components/others/correctionStepScreen/CorrectionDesktopContent";
import CorrectionTabletContent from "../../components/others/correctionStepScreen/CorrectionTabletContent";
import CorrectionMobileContent from "../../components/others/correctionStepScreen/CorrectionMobileContent";

function CorrectionStepScreen({open, handleClose, question, questionIndex}) {
    // Used in ResultsQuestionItem component to show single question
    // correction

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                <div className="overflow-auto w-full h-screen flex flex-col items-center justify-end outline-none">
                    {/** MOBILE */}
                    <CorrectionMobileGhostTopBar handleClose={handleClose} />
                    <CorrectionMobileLayout>
                        <CorrectionMobileContent question={question}
                                                 questionIndex={questionIndex}
                                                 handleClose={() => handleClose()} />
                    </CorrectionMobileLayout>
                    {/** TABLET */}
                    <CorrectionTabletGhostTopBar handleClose={handleClose} />
                    <CorrectionTabletLayout>
                        <SystemBottomSheetClosingIcon onPressed={handleClose}
                                                      closingIconSize={ClosingIconSize.MEDIUM} />
                        <CorrectionTabletContent question={question}
                                                 questionIndex={questionIndex} />
                    </CorrectionTabletLayout>
                    {/** DESKTOP */}
                    <CorrectionDesktopGhostTopBar handleClose={() => handleClose()} />
                    <CorrectionDesktopLayout>
                        <SystemBottomSheetClosingIcon onPressed={handleClose}
                                                      closingIconSize={ClosingIconSize.MEDIUM} />
                        <CorrectionDesktopContent question={question}
                                                  questionIndex={questionIndex} />
                    </CorrectionDesktopLayout>
                </div>
            </Slide>
        </Modal>
    );
}

// EXPORT
export default CorrectionStepScreen