import {isNullOrUndefined} from "../../../services/Validator";

function PreviewQuestionMobileLayout({children}) {
    // Used in QuestionsStepScreen to frame screen content in
    // desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto w-full grow flex flex-col sm:hidden"}>
            {children}
        </div>
    );
}

// EXPORT
export default PreviewQuestionMobileLayout