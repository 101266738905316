import AboutMobileSectionTopTitleText from "../../texts/aboutScreen/AboutMobileSectionTopTitleText";
import AboutMobileMethodSolutionIntroText from "../../texts/aboutScreen/AboutMobileMethodSolutionIntroText";
import {kMethodQuestion} from "../../../constants/objects";
import {useState} from "react";
import AboutMobileSectionLayout from "../../layouts/aboutScreen/AboutMobileSectionLayout";
import {kAboutNavigation} from "../../../constants/navigation";
import AboutMobileSectionTitleText from "../../texts/aboutScreen/AboutMobileSectionTitleText";
import AboutMobileTopTitleLayout from "../../layouts/aboutScreen/AboutMobileTopTitleLayout";
import AboutMobileSectionCoreText from "../../texts/aboutScreen/AboutMobileSectionCoreText";
import AboutMobileItemLayout from "../../layouts/aboutScreen/AboutMobileItemLayout";
import AboutMobileItemTitleText from "../../texts/aboutScreen/AboutMobileItemTitleText";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import AboutImage from "../../images/aboutScreen/AboutImage";
import SystemQuestionKnowledge from "../SystemQuestionKnowledge";
import SystemDraftQuestionCard from "../../cards/SystemDraftQuestionCard";
import {kAboutMethodTexts} from "../../../constants/texts";
import SystemOptionsCard from "../../cards/SystemOptionsCard";


function AboutMobileMethodSection({selectedTab}) {
    // Used in AboutMobileContent to explain app
    // content approach and methodology

    // PROPERTIES
    const [selectedOption, setSelectedOption] = useState(null)

    // BUILD
    return(
        <AboutMobileSectionLayout>
            <AboutMobileSectionTitleText pageTitle={kAboutNavigation[selectedTab].title}/>
            {/** STATEMENT */}
            <AboutMobileTopTitleLayout>
                <AboutImage imageUrl={'/images/system/flashLogo.png'} />
                <AboutMobileSectionTopTitleText title={kAboutMethodTexts.title} />
            </AboutMobileTopTitleLayout>
            <SystemCustomDivider color={'bg-greyLight5'} />
            {/** PROBLEM */}
            <AboutMobileSectionCoreText text={kAboutMethodTexts.intro} />
            <AboutMobileItemLayout>
                <AboutMobileItemTitleText title={kAboutMethodTexts.problemOneTitle} />
                <AboutMobileSectionCoreText text={kAboutMethodTexts.problemOne} />
            </AboutMobileItemLayout>
            <AboutMobileItemLayout>
                <AboutMobileItemTitleText title={kAboutMethodTexts.problemTwoTitle} />
                <AboutMobileSectionCoreText text={kAboutMethodTexts.problemTwo} />
            </AboutMobileItemLayout>
            <SystemCustomDivider color={'bg-greyLight5'} />
            {/** SOLUTION */}
            <AboutMobileMethodSolutionIntroText />
            <SystemDraftQuestionCard question={kMethodQuestion}
                                     selectedOption={selectedOption}
                                     setSelectedOption={setSelectedOption} />
            <AboutMobileItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/atom.png'} />
                <AboutMobileItemTitleText title={kAboutMethodTexts.solutionOneTitle} />
                <AboutMobileSectionCoreText text={kAboutMethodTexts.solutionOne} />
            </AboutMobileItemLayout>
            <AboutMobileItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/pastFuture.png'} />
                <AboutMobileItemTitleText title={kAboutMethodTexts.solutionTwoTitle} />
                <AboutMobileSectionCoreText text={kAboutMethodTexts.solutionTwo} />
            </AboutMobileItemLayout>
            <AboutMobileItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/repeat.png'} />
                <AboutMobileItemTitleText title={kAboutMethodTexts.solutionThreeTitle} />
                <AboutMobileSectionCoreText text={kAboutMethodTexts.solutionThree} />
            </AboutMobileItemLayout>
            <AboutMobileItemLayout>
                <AboutImage imageUrl={'/images/aboutScreen/standUp.png'} />
                <AboutMobileItemTitleText title={kAboutMethodTexts.solutionFourTitle} />
                <AboutMobileSectionCoreText text={kAboutMethodTexts.solutionFour} />
            </AboutMobileItemLayout>
            <SystemQuestionKnowledge knowledge={"Knowledge"} />
            <SystemOptionsCard question={kMethodQuestion} />
        </AboutMobileSectionLayout>
    );
}

// EXPORT
export default AboutMobileMethodSection

