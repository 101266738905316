import {useState} from "react";
import {pressDontKnow} from "../../../controllers/QuestionOptionItemController";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import {renderQuestionOptionStyle} from "../../../controllers/OptionItemController";

function QuestionsMobileCardOptionItem({isLast, option, optionSelected, setOptionSelected, onDontKnowPress}) {
    // Used in QuestionsMobileCardOptionsList to display
    // one single option item

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return(
        <div className={`bg-backgroundLight5 flex items-center ${isLast ? "justify-between" : "justify-start"} px-12 py-8 h-[48px] min-h-[48px] leading-none border-1 rounded-[8px] cursor-pointer ${renderQuestionOptionStyle(option, optionSelected, isHovered)}`}
             onClick={() => setOptionSelected(option)}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}>
            <MathJaxContext>
                <MathJax inline dynamic>
                    <span className="font-medium text-[18px]">
                        {option?.proposal ?? "Option"}
                    </span>
                </MathJax>
            </MathJaxContext>
            {isLast ? <i onClick={(event) => pressDontKnow(event, onDontKnowPress)} className="material-icons text-[18px] lg:text-[20px] text-greyLight7 transition ease-in-out hover:text-greyLight11 duration-100">help</i> : null}
        </div>
    );
}

// EXPORT
export default QuestionsMobileCardOptionItem