
function LandingDesktopConfusedImage() {
    // Used in

    // BUILD
    return (
        <div className={"h-[96px] min-h-[96px] flex justify-center items-center"}>
            <img className={"object-contain w-full h-full"} src={'/images/landingScreen/confusedBear.png'} alt="" />
        </div>
    );
}

// EXPORT
export default LandingDesktopConfusedImage