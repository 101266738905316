import LawMobileBulletSectionText from "../lawScreens/LawMobileBulletSectionText";
import LawMobileSectionCoreText from "../lawScreens/LawMobileSectionCoreText";
import {kPrivacyTexts} from "../../../constants/texts";

function PrivacyMobileCollectedDataText() {
    // Used in PrivacyScreen to display collected data bullet list

    // BUILD
    return (
        <div className={"flex flex-col items-start justify-start space-y-6"}>
            <LawMobileSectionCoreText text={kPrivacyTexts.collectedDataOne} />
            <LawMobileBulletSectionText text={kPrivacyTexts.collectedDataTwo} />
            <LawMobileBulletSectionText text={kPrivacyTexts.collectedDataThree} />
            <LawMobileBulletSectionText text={kPrivacyTexts.collectedDataFour} />
        </div>
    );
}

// EXPORT
export default PrivacyMobileCollectedDataText