

// METHODS
// ---- Render last update date text
export function renderDateText(lastUpdateDate, isLegals) {
    switch (isLegals) {
        case true :
            return `Mises à jour le : ${lastUpdateDate}`
        case false :
            return `Mise à jour le : ${lastUpdateDate}`
        default:
            return `Mis à jour le : ${lastUpdateDate}`
    }
}