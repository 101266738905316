import AboutMobileMissionSection from "./AboutMobileMissionSection";
import AboutMobileMethodSection from "./AboutMobileMethodSection";
import AboutMobileTeamSection from "./AboutMobileTeamSection";
import AboutMobileContactSection from "./AboutMobileContactSection";

function AboutMobileContent({selectedTab, setSelectedTab}) {
    // Used in AboutScreen to display about selected tab
    // in mobile mode

    // BUILD
    return (
        <div className={"animate-appear bg-backgroundLight3 overflow-y-auto w-full grow sm:hidden"}>
            {_renderSelectedTab()}
        </div>
    );

    // ---- Render selected tab content
    function _renderSelectedTab() {
        switch (selectedTab) {
            case (0):
                return <AboutMobileMissionSection selectedTab={selectedTab}
                                                  goToMethod={() => setSelectedTab(1)} />
            case (1):
                return <AboutMobileMethodSection selectedTab={selectedTab} />
            case (2):
                return <AboutMobileTeamSection selectedTab={selectedTab} />
            case (3):
                return <AboutMobileContactSection selectedTab={selectedTab} />
            default :
                return <AboutMobileMissionSection selectedTab={selectedTab}
                                                  goToMethod={() => setSelectedTab(1)} />
        }
    }
}

// EXPORT
export default AboutMobileContent