import {isNullOrUndefined} from "../../../services/Validator";

function AboutDesktopTabletTopTitleLayout({children}) {
    // Used in about screen to frame section top title

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"w-full flex flex-col items-center space-y-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletTopTitleLayout