import {useNavigate} from "react-router-dom";
import {useState} from "react";
import SystemMobileTopBarLayout from "../../layouts/SystemMobileTopBarLayout";
import {routes} from "../../../config/routes";
import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import SystemMobileBurgerMenuIconButton from "../../buttons/SystemMobileBurgerMenuIconButton";
import SystemCollapsableMenuLayout from "../../layouts/SystemCollapsableMenuLayout";
import SystemCollapsableMenuItem from "../../items/SystemCollapsableMenuItem";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";

function LawMobileTopBar() {
    // Used in both Legals and Privacy screens to display
    // top bar in mobile mode

    // PROPERTIES
    let navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    // BUILD
    return (
        <div className={"w-full flex flex-col sm:hidden bg-background"}>
            <SystemMobileTopBarLayout>
                <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.XSMALL}
                                          onLogoPressed={() => navigate(routes.landing)} />
                <SystemMobileBurgerMenuIconButton onPressed={() => setIsMenuOpen(!isMenuOpen)} />
            </SystemMobileTopBarLayout>
            <SystemCollapsableMenuLayout isOpen={isMenuOpen} >
                <SystemCollapsableMenuItem label={"Retour à l'accueil"} icon={"home"} onPress={() => navigate(routes.landing)} />
                <SystemCustomDivider color={"bg-greyLight3"} />
                <SystemCollapsableMenuItem label={"Lancer un flash"} icon={"bolt"} onPress={() => navigate(routes.flash)} />
            </SystemCollapsableMenuLayout>
        </div>
    );
}

// EXPORT
export default LawMobileTopBar