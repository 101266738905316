import SystemMobileFlashLogoImage from "../../images/SystemMobileFlashLogoImage";
import QuestionsMobileProgressText from "../../texts/questionsStepScreen/QuestionsMobileProgressText";
import SystemMobileTopBarLayout from "../../layouts/SystemMobileTopBarLayout";
import SystemFlashSelectedGradeBadge from "../../badges/SystemFlashSelectedGradeBadge";
import SystemMobileLeaveFlashIcon from "../../icons/SystemMobileLeaveFlashIcon";

function QuestionsMobileTopBar({onLeaveFlashPressed, currentQuestion, total, selectedGrade}) {
    // Used in QuestionsStepScreen to display flash logo, title and
    // leave flash icon

    // BUILD
    return (
        <SystemMobileTopBarLayout>
            <div className={"w-[64px] min-w-[64px] flex justify-start items-center space-x-6"}>
                <SystemMobileFlashLogoImage />
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </div>
            <QuestionsMobileProgressText currentQuestion={currentQuestion}
                                         totalQuestion={total} />
            <SystemMobileLeaveFlashIcon onPressed={onLeaveFlashPressed}
                                        extraClass={"w-[64px] min-w-[64px]"} />
        </SystemMobileTopBarLayout>
    );
}

// EXPORT
export default QuestionsMobileTopBar