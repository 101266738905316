import {isNullOrUndefined} from "../../../services/Validator";

function LawDesktopTabletSectionTitleText({index, title}) {
    // Used in LawDesktopTabletContent to display
    // paragraph title text

    // BUILD
    if (isNullOrUndefined(title))
        return null
    return (
        <div className={"text-[20px] text-greyLight11 flex space-x-8 items-start justify-start"}>
            {index === null || index === undefined
                ? null
                : <span className={"font-normal"}>
                    {index + "."}
                  </span>
            }
            <span className={"font-bold"}>
                {title}
            </span>
        </div>
    );
}

// EXPORT
export default LawDesktopTabletSectionTitleText