import {isNullOrUndefined} from "../../../services/Validator";

function LawContentDesktopTabletContentLayout({children}) {
    // Used in both Legals and Privacy screens to
    // frame content in both desktop and tablet modes

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"hidden grow sm:w-[560px] md:w-[640px] lg:w-[720px] sm:flex sm:flex-col items-center justify-start space-y-24 py-32"}>
            {children}
        </div>
    );
}

// EXPORT
export default LawContentDesktopTabletContentLayout