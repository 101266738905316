import {SystemPrimaryCustomButton, SystemSecondaryCustomButton} from "../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../constants/styles";
import {isArrayEmpty, isNullOrUndefined} from "../../../services/Validator";
import {CircularProgress} from "@mui/material";

function GradeTabletBottomBar({selectedGrade,
                               selectedLevelAs,
                               fetchFlashQuestions,
                               isFetchingLoading,
                               goToPreviousStep,
                               extraClass}) {
    // Used in SubthemeStepsScreen to display bottom bar with
    // action buttons in tablet mode

    // BUILD
    return (
        <div className={"w-full flex space-x-24 items-center justify-center " + extraClass}>
            <SystemSecondaryCustomButton
                label={"Précédent"}
                isDisabled={false}
                isLoading={false}
                isFullWidth={false}
                sizeStyle={kButtonStyles.largeButtonStyle}
                onButtonClick={isFetchingLoading ? null : () => goToPreviousStep()}
                extraClass={kButtonStyles.flashRuleFixedWidth}/>
            <SystemPrimaryCustomButton
                label={(isNullOrUndefined(selectedGrade) || !isArrayEmpty(selectedLevelAs)) ? "Lancer le flash" : "Sélectionner des savoir-faire"}
                isDisabled={isArrayEmpty(selectedLevelAs)}
                isLoading={isFetchingLoading}
                loaderComponent={<CircularProgress size={"28px"}
                                                   style={{'color' : 'white'}} />}
                isFullWidth={true}
                sizeStyle={kButtonStyles.largeButtonStyle}
                onButtonClick={() => fetchFlashQuestions()}
                extraClass={kButtonStyles.flashRuleFixedWidth}/>
        </div>
    );
}

// EXPORT
export default GradeTabletBottomBar