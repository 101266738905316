
function AboutMobileItemLayout({children}) {
    // Used in AboutScreen sub-screens to frame
    // text made of both a title and a text

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-6"}>
            {children}
        </div>
    );
}

// EXPORT
export default AboutMobileItemLayout