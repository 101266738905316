import {isArrayNullUndefinedOrEmpty} from "../../../services/Validator";
import ParameterMobileSubthemeItem from "../../items/parameterStepScreen/ParameterMobileSubthemeItem";
import ParameterMobileSubthemesVoid from "../../voids/parameterStepScreen/ParameterMobileSubthemesVoid";

function ParameterMobileSubthemesList({subthemes, selectSubtheme, selectedSubtheme}) {
    // Used in GradeMobileContent and LevelDesktopContent to
    // display list of flash levels to pick

    // BUILD
    if (isArrayNullUndefinedOrEmpty(subthemes))
        return <ParameterMobileSubthemesVoid />
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow flex flex-col items-start justify-start"}>
            {subthemes?.map((subtheme, index) => (
                <ParameterMobileSubthemeItem key={index}
                                             subtheme={subtheme}
                                             selectSubtheme={selectSubtheme}
                                             selectedSubtheme={selectedSubtheme} />
            ))}
        </div>
    );
}

// EXPORT
export default ParameterMobileSubthemesList