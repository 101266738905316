import ParameterSubthemesContent from "./ParameterSubthemesContent";
import ParameterGradesList from "../../lists/parameterStepScreen/ParameterGradesList";
import {GradePickerSize} from "../../../controllers/GradeBadgeItemController";

function ParameterContent({grades,
                             selectedGrade,
                             setFlashGrade,
                             isSubthemesLoading,
                             hasSubthemesError,
                             subthemes,
                             selectSubtheme,
                             selectedSubtheme,
                             levelAs,
                             selectedLevelAs,
                             toggleLevelA}) {
    // Used in GradeStepScreen to display level full content
    // in desktop mode

    // BUILD
    return (
        <div className={"overflow-auto w-full h-full min-h-[400px] flex flex-col"}>
            <ParameterGradesList grades={grades}
                                 selectedGrade={selectedGrade}
                                 setFlashGrade={setFlashGrade}
                                 gradePickerSize={GradePickerSize.MEDIUM}
                                 extraClass={"mt-16"} />
            <ParameterSubthemesContent selectedGrade={selectedGrade}
                                       isSubthemesLoading={isSubthemesLoading}
                                       hasSubthemesError={hasSubthemesError}
                                       subthemes={subthemes}
                                       selectSubtheme={selectSubtheme}
                                       selectedSubtheme={selectedSubtheme}
                                       levelAs={levelAs} selectedLevelAs={selectedLevelAs}
                                       toggleLevelA={toggleLevelA} />
        </div>
    );
}

// EXPORT
export default ParameterContent