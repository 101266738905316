
// ---- Render Subtheme text style
export function renderSubthemeStyle(subtheme, selectedSubtheme, isHovered) {
    if (isSubthemeSelected(subtheme, selectedSubtheme)) {
        return "text-primaryLight5"
    } else {
        return isHovered ? "text-white" : "text-greyLight11"
    }
}

// ---- Render question option style
export function renderSubthemeThemeStyle(subtheme, selectedSubtheme, isHovered) {
    if (isSubthemeSelected(subtheme, selectedSubtheme)) {
        return "text-greyLight11"
    } else {
        return isHovered ? "text-greyLight9" : "text-greyLight7"
    }
}

// ---- Test if current option is selected option
export function isSubthemeSelected(subtheme, selectedSubtheme) {
    if (subtheme !== null && selectedSubtheme !== null) {
        return subtheme.name === selectedSubtheme.name
    } else {
        return false
    }
}