import {isNullOrUndefined} from "../../../services/Validator";

function AboutDesktopContentLayout({children}) {
    // Used in AboutScreen to frame content in desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"sm:w-[560px] lg:w-[708px] grow hidden sm:flex sm:flex-col items-center justify-start"}>
            {children}
        </div>
    );
}

// EXPORT
export default AboutDesktopContentLayout