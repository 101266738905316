import Modal from "@mui/material/Modal";
import {kModalStyles} from "../../constants/styles";
import {isNullOrUndefined} from "../../services/Validator";
import {
    computePreferredAxis,
    computeZoomedQuestionPictureSize
} from "../../controllers/questionsStepScreen/QuestionPictureController";
import {useState} from "react";

function SystemZoomedPictureDialog({open, handleClose, pictureUrl, pictureRatio}) {
    // Used in SystemQuestionPicture and to display
    // zoomed Question picture in dialog

    // PROPERTIES
    const [hasError, setHasError] = useState(false);

    // BUILD
    return(
        <Modal open={open}
               onClose={handleClose}>
            <div className={kModalStyles.dialogZoomedPictureStyle}>
                {hasError || isNullOrUndefined(pictureUrl)
                    ? <div>Une erreur est survenue</div>
                    : <div className={`overflow-hidden rounded-[8px] ${computeZoomedQuestionPictureSize(pictureRatio, false)}`}>
                            <img src={pictureUrl}
                                 alt="Question"
                                 className={`object-cover ${computePreferredAxis(pictureRatio)}`}
                                 onError={() => setHasError(true)}
                                 onLoad={() => setHasError(false)} />
                      </div>}
            </div>
        </Modal>
    )
}

// EXPORT
export default SystemZoomedPictureDialog