import AboutDesktopTabletSectionLayout from "../../layouts/aboutScreen/AboutDesktopTabletSectionLayout";
import AboutDesktopTabletTopTitleLayout from "../../layouts/aboutScreen/AboutDesktopTabletTopTitleLayout";
import AboutDesktopTabletSectionTopTitleText from "../../texts/aboutScreen/AboutDesktopTabletSectionTopTitleText";
import AboutDesktopTabletSectionCoreText from "../../texts/aboutScreen/AboutDesktopTabletSectionCoreText";
import AboutDesktopTabletItemLayout from "../../layouts/aboutScreen/AboutDesktopTabletItemLayout";
import AboutDesktopTabletItemTitleText from "../../texts/aboutScreen/AboutDesktopTabletItemTitleText";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import AboutImage from "../../images/aboutScreen/AboutImage";
import {kAboutTeamTexts} from "../../../constants/texts";

function AboutDesktopTabletTeamSection() {
    // Used in

    // BUILD
    return(
        <AboutDesktopTabletSectionLayout>
            {/** STATEMENT */}
            <AboutDesktopTabletTopTitleLayout>
                <AboutImage imageUrl={'/images/aboutScreen/teacher.png'} />
                <AboutDesktopTabletSectionTopTitleText title={kAboutTeamTexts.title} />
            </AboutDesktopTabletTopTitleLayout>
            <SystemCustomDivider color={'bg-greyLight5'} />
            {/** DETAILS */}
            <AboutDesktopTabletSectionCoreText text={kAboutTeamTexts.intro} />
            <AboutDesktopTabletItemLayout>
                <AboutDesktopTabletItemTitleText title={kAboutTeamTexts.oneName} />
                <AboutDesktopTabletSectionCoreText text={kAboutTeamTexts.one} />
            </AboutDesktopTabletItemLayout>
            <AboutDesktopTabletItemLayout>
                <AboutDesktopTabletItemTitleText title={kAboutTeamTexts.twoName} />
                <AboutDesktopTabletSectionCoreText text={kAboutTeamTexts.two} />
            </AboutDesktopTabletItemLayout>
            <AboutDesktopTabletItemLayout>
                <AboutDesktopTabletItemTitleText title={kAboutTeamTexts.threeName} />
                <AboutDesktopTabletSectionCoreText text={kAboutTeamTexts.three} />
            </AboutDesktopTabletItemLayout>
            <AboutDesktopTabletItemLayout>
                <AboutDesktopTabletItemTitleText title={kAboutTeamTexts.fourName} />
                <AboutDesktopTabletSectionCoreText text={kAboutTeamTexts.four} />
            </AboutDesktopTabletItemLayout>
        </AboutDesktopTabletSectionLayout>
    );
}

// EXPORT
export default AboutDesktopTabletTeamSection