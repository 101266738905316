import {isNullOrUndefined} from "../../../services/Validator";

function AboutMobileSectionTitleText({pageTitle}) {
    // Used in AboutMobileMissionSection to display
    // tab view title

    // BUILD
    if(isNullOrUndefined(pageTitle))
        return null
    return (
        <div className={"w-full flex justify-start"}>
            <span className={"text-[20px] text-white font-bold"}>
                {pageTitle}
            </span>
        </div>
    );
}

// EXPORT
export default AboutMobileSectionTitleText