import {SystemPrimaryCustomButton} from "../components/buttons/SystemCustomButton";
import {kButtonStyles} from "../constants/styles";
import {routes} from "../config/routes";

function ErrorRoute() {
    // Error Component used in index.js Browser Router, to manage routing not found error

    // BUILD
    return (
        <div className={"overflow-auto w-full h-screen flex flex-col justify-start items-center space-y-32 p-16 sm:p-32 lg:p-48"}>
            <div className={`rounded-[16px] overflow-hidden w-full h-[400px] min-h-[360px] sm:w-[600px] sm:h-full sm:min-h-[400px] lg:w-[900px] lg:min-h-[560px]`}>
                <img src={"/images/system/404ErrorImage.png"}
                     alt="Page not found"
                     className={`object-cover h-full`} />
            </div>
            <SystemPrimaryCustomButton
                label={"Revenir à l'accueil"}
                isDisabled={false}
                isLoading={false}
                isFullWidth={false}
                sizeStyle={kButtonStyles.largeButtonStyle}
                internalLink={routes.landing}
                extraClass={kButtonStyles.flashRuleFixedWidth}/>
        </div>
    );
}

export default ErrorRoute