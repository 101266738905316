import SystemMobileFlashLogoImage from "../../images/SystemMobileFlashLogoImage";
import SystemMobileTopBarLayout from "../../layouts/SystemMobileTopBarLayout";
import SystemFlashStepTitleText from "../../texts/SystemFlashStepTitleText";
import {kFlashTexts} from "../../../constants/texts";
import SystemFlashStepSubtitleText from "../../texts/SystemFlashStepSubtitleText";
import SystemMobileLeaveFlashIcon from "../../icons/SystemMobileLeaveFlashIcon";

function ParameterMobileTopBar({goBackToHome}) {
    // Used in GradeStepScreen to display top bar with
    // screen title and leave flash action button in mobile mode

    // BUILD
    return (
        <div className={"w-full flex flex-col sm:hidden"}>
            <SystemMobileTopBarLayout>
                <SystemMobileFlashLogoImage extraClass={"w-[20px] min-w-[20px]"} />
                <SystemFlashStepTitleText text={kFlashTexts.parameterScreenTitle} />
                <SystemMobileLeaveFlashIcon onPressed={goBackToHome} />
            </SystemMobileTopBarLayout>
            <SystemFlashStepSubtitleText text={kFlashTexts.parameterScreenSubtitle}
                                         extraClass={"px-12 -mt-4"} />
        </div>
    );
}

// EXPORT
export default ParameterMobileTopBar