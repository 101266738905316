import {isNullOrUndefined} from "../../../services/Validator";

function LawDesktopTabletTitleText({pageTitle}) {
    // Used in side content screen to show page title

    // BUILD
    if (isNullOrUndefined(pageTitle))
        return null
    return (
        <div>
            <span className={"text-[24px] text-white font-bold"}>
                {pageTitle}
            </span>
        </div>
    );
}

// EXPORT
export default LawDesktopTabletTitleText