import {useState} from "react";
import AboutMobileContent from "../components/others/aboutScreen/AboutMobileContent";
import AboutDesktopContentLayout from "../components/layouts/aboutScreen/AboutDesktopContentLayout";
import AboutDesktopTabletPageTitleText from "../components/texts/aboutScreen/AboutDesktopTabletPageTitleText";
import AboutDesktopTabletContent from "../components/others/aboutScreen/AboutDesktopTabletContent";
import AboutDesktopTabBar from "../components/bars/aboutScreen/AboutDesktopTabBar";
import AboutMobileTabBar from "../components/bars/aboutScreen/AboutMobileTabBar";
import AboutMobileTopBar from "../components/bars/aboutScreen/AboutMobileTopBar";
import AboutTabletTopBar from "../components/bars/aboutScreen/AboutTabletTopBar";
import AboutDesktopTopBar from "../components/bars/aboutScreen/AboutDesktopTopBar";
import AboutTabletTabBar from "../components/bars/aboutScreen/AboutTabletTabBar";
import AboutDesktopLayout from "../components/layouts/aboutScreen/AboutDesktopLayout";


function AboutScreen() {
    // About Screen used in index.js to display multiple screens

    // PROPERTIES
    const [selectedTab, setSelectedTab] = useState(0)

    // BUILD
    return(
        <div className={"w-full h-screen flex flex-col overflow-hidden bg-backgroundLight3"}>
             {/** MOBILE */}
             <AboutMobileTopBar />
             <AboutMobileTabBar selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab} />
             <AboutMobileContent selectedTab={selectedTab}
                                 setSelectedTab={setSelectedTab} />
             {/** TABLET + DESKTOP */}
             <AboutTabletTopBar />
             <AboutDesktopTopBar />
             <AboutDesktopLayout>
                 <AboutDesktopContentLayout>
                     <AboutDesktopTabletPageTitleText />
                     <AboutTabletTabBar selectedTab={selectedTab}
                                        setSelectedTab={setSelectedTab} />
                     <AboutDesktopTabBar selectedTab={selectedTab}
                                         setSelectedTab={setSelectedTab} />
                     <AboutDesktopTabletContent selectedTab={selectedTab}
                                                setSelectedTab={setSelectedTab} />
                 </AboutDesktopContentLayout>
            </AboutDesktopLayout>
        </div>
    );

}

// EXPORT
export default AboutScreen