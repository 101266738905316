import ParameterGradesList from "../../lists/parameterStepScreen/ParameterGradesList";
import {GradePickerSize} from "../../../controllers/GradeBadgeItemController";
import ParameterMobileSubthemesContent from "./ParameterMobileSubthemesContent";

function ParameterMobileContent({grades,
                                selectedGrade,
                                setFlashGrade,
                                isSubthemesLoading,
                                hasSubthemesError,
                                subthemes,
                                selectSubtheme,
                                selectedSubtheme,
                                levelAs,
                                selectedLevelAs,
                                toggleLevelA,
                                displayMobileLevelAs,
                                goBackToSubthemes}) {
    // Used in ParameterStepScreen to display whole Grade, Subtheme
    // and LevelA picker in mobile mode

    // BUILD
    return (
        <div className={"overflow-auto w-full grow flex flex-col"}>
            <ParameterGradesList grades={grades}
                                 selectedGrade={selectedGrade}
                                 setFlashGrade={setFlashGrade}
                                 gradePickerSize={GradePickerSize.XSMALL}
                                 extraClass={"mt-10"} />
            <ParameterMobileSubthemesContent selectedGrade={selectedGrade}
                                             isSubthemesLoading={isSubthemesLoading}
                                             hasSubthemesError={hasSubthemesError}
                                             subthemes={subthemes}
                                             selectSubtheme={selectSubtheme}
                                             selectedSubtheme={selectedSubtheme}
                                             levelAs={levelAs} selectedLevelAs={selectedLevelAs}
                                             toggleLevelA={toggleLevelA}
                                             displayMobileLevelAs={displayMobileLevelAs}
                                             goBackToSubthemes={goBackToSubthemes} />
        </div>
    )
}

// EXPORT
export default ParameterMobileContent