import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";
import {api} from "./routes";

// List of methods triggering Questions related api calls

// ---- Get single Question from id
export function get_single_question(id) {
    const url = api.domain + generatePath(api.routes.singleQuestion, { id })
    return makeRequest(url, Methods.GET)
}

// ---- Get leveled questions
// export function get_leveled_questions(id) {
//     const url = api.domain + generatePath(api.routes.leveledQuestions, { id })
//     return makeRequest(url, Methods.GET)
// }

// ---- Get Question based on list of LevelAsIds
export function get_questions_from_levelAsIds(data) {
    const url = api.domain + generatePath(api.routes.questionsFromLevelAsIds)
    return makeRequest(url, Methods.POST, data)
}