import {renderFlashLogoTextSize} from "../../../controllers/FlashLogoTextController";

function PreviewLogoTextImage({logoSize}) {
    // Used through the app to display flash logo image in desktop
    // mode which is the text version of flash logo

    // BUILD
    return (
        <div className={`animate-appear ${renderFlashLogoTextSize(logoSize)} mb-4`}>
            <img className={"h-full"} src={'/images/previewQuestionScreen/previewLogoText.png'} alt="Logo" />
        </div>
    );
}

// EXPORT
export default PreviewLogoTextImage