

// METHODS
// ---- Render quiz score bear emoji regarding success ratio
export function renderScoreEmoji(successRatio) {
    if (successRatio === 100) {
        return "/images/emojiBearSuccess.png"
    } else if (successRatio >= 80) {
        return "/images/emojiBearAlmost.png"
    } else if (successRatio >= 60) {
        return "/images/emojiBearAverage.png"
    } else if (successRatio >= 40) {
        return "/images/emojiBearSceptic.png"
    } else if (successRatio >= 20) {
        return "/images/emojiBearConfused.png"
    } else {
        return "/images/emojiBearConfused.png"
    }
}