import {Link} from "react-router-dom";
import {routes} from "../../../config/routes";
import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import {SystemPrimaryCustomButton} from "../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../constants/styles";
import SystemTabletTopBarLayout from "../../layouts/SystemTabletTopBarLayout";
import {kFlashTexts} from "../../../constants/texts";

function LawTabletTopBar() {
    // Used in

    // BUILD
    return (
        <SystemTabletTopBarLayout extraClass={"bg-background"}>
            <Link to={routes.landing}>
                <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.SMALL} />
            </Link>
            <div className={"flex space-x-16 items-center justify-end text-greyLight9 hover:text-greyLight11"}>
                <Link to={routes.landing}>
                    <span className={"text-[16px] font-medium"}>
                        {kFlashTexts.backToHome}
                    </span>
                </Link>
                <SystemPrimaryCustomButton
                    label={"Lancer un flash"}
                    isDisabled={false}
                    isLoading={false}
                    isFullWidth={false}
                    sizeStyle={kButtonStyles.mediumButtonStyle}
                    onButtonClick={null}
                    internalLink={routes.flash}
                    extraClass={'animate-appear'}
                />
            </div>
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default LawTabletTopBar