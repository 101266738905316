import {useEffect, useRef, useState} from "react";
import ResultsTabletSummary from "../../components/others/resultsStepScreen/ResultsTabletSummary";
import ResultsTabletQuestionsCard from "../../components/cards/resultsStepScreen/ResultsTabletQuestionsCard";
import ResultsMobileBottomContentLayout
    from "../../components/layouts/resultsStepScreen/ResultsMobileBottomContentLayout";
import ResultsDesktopContentLayout
    from "../../components/layouts/resultsStepScreen/ResultsDesktopContentLayout";
import ResultsDesktopQuestionsCard from "../../components/cards/resultsStepScreen/ResultsDesktopQuestionsCard";
import ResultsDesktopSummaryLayout from "../../components/layouts/resultsStepScreen/ResultsDesktopSummaryLayout";
import ResultsDesktopScoreCard from "../../components/cards/resultsStepScreen/ResultsDesktopScoreCard";
import ResultsDesktopGraphCard from "../../components/cards/resultsStepScreen/ResultsDesktopGraphCard";
import ResultsMobileTabBar from "../../components/bars/resultsStepScreen/ResultsMobileTabBar";
import ResultsGraphList from "../../components/lists/resultsStepScreen/ResultsGraphList";
import ResultsQuestionsList from "../../components/lists/resultsStepScreen/ResultsQuestionsList";
import ResultsMobileTopBar from "../../components/bars/resultsStepScreen/ResultsMobileTopBar";
import ResultsMobileContentLayout from "../../components/layouts/resultsStepScreen/ResultsMobileContentLayout";
import ResultsTabletTopBar from "../../components/bars/resultsStepScreen/ResultsTabletTopBar";
import ResultsDesktopTopBar from "../../components/bars/resultsStepScreen/ResultsDesktopTopBar";
import ResultsTabletContentLayout from "../../components/layouts/resultsStepScreen/ResultsTabletContentLayout";
import SystemLeaveFlashDialog from "../../components/dialogs/SystemLeaveFlashDialog";
import ResultsSummary from "../../components/others/resultsStepScreen/ResultsSummary";
import {isArrayNullUndefinedOrEmpty} from "../../services/Validator";
import {kFlashTexts} from "../../constants/texts";
import ExistingFlashSnackBar from "../../components/snackbars/ExistingFlashSnackBar";
import SystemFlashTopTexts from "../../components/texts/SystemFlashTopTexts";
import ResultsDesktopTopTexts from "../../components/texts/resultsStepScreen/ResultsDesktopTopTexts";
import {EventName} from "../../enums/PageViewMetric";
import {recordPageViewMetric} from "../../controllers/SystemPageViewMetricController";

function ResultsStepScreen({selectedGrade,
                            questions,
                            isLeaveModalOpen,
                            setIsLeaveModalOpen,
                            onLeaveFlashPressed,
                            leaveFlash,
                            isSnackbarOpen,
                            handleClose}) {
    // Used in FlashScreen component to display flash results made of global
    // score, graphical representation, and questions list with access to single
    // correction screen

    // PROPERTIES
    const [selectedTab, setSelectedTab] = useState(0)
    // ---- PageViewMetric
    const hasTrackedView = useRef(false);

    // HOOKS
    // ---- At rendering : if local flash is in storage, set flash variables
    useEffect(() => {
        if (!hasTrackedView.current) {
            // Avoid double trigger in dev, due to React Strict mode
            recordPageViewMetric(EventName.FLASH_RESULTS);
            hasTrackedView.current = true;
        }
    }, []);

    // BUILD
    if (isArrayNullUndefinedOrEmpty(questions))
        return null;
    return(
        <div className="overflow-auto animate-appear w-full h-screen bg-backgroundLight3 flex flex-col justify-start items-center">
            {/** SNACKBAR */}
            <ExistingFlashSnackBar open={isSnackbarOpen}
                                   handleClose={handleClose} />
            {/** MODAL */}
            <SystemLeaveFlashDialog open={isLeaveModalOpen}
                                    handleClose={() => setIsLeaveModalOpen(false)}
                                    leaveFlash={leaveFlash} />
            {/** MOBILE */}
            <ResultsMobileTopBar selectedGrade={selectedGrade}
                                 onLeaveFlashPressed={onLeaveFlashPressed} />
            <ResultsMobileContentLayout>
                <ResultsSummary scores={computeFlashScores()[0]}
                                total={questions?.length} />
                <ResultsMobileTabBar selectedTab={selectedTab}
                                     setSelectedTab={setSelectedTab} />
                <ResultsMobileBottomContentLayout>
                    {_renderResultsMobileSelectedTab()}
                </ResultsMobileBottomContentLayout>
            </ResultsMobileContentLayout>
            {/** TABLET */}
            <ResultsTabletTopBar selectedGrade={selectedGrade}
                                 onLeaveFlashPressed={onLeaveFlashPressed} />
            <ResultsTabletContentLayout>
                <SystemFlashTopTexts title={kFlashTexts.resultsTopTitle}
                                     subtitle={kFlashTexts.resultsTopSubtitle} />
                <ResultsTabletSummary scores={computeFlashScores()[0]}
                                      total={questions?.length} />
                <ResultsTabletQuestionsCard questions={questions} />
            </ResultsTabletContentLayout>
            {/** DESKTOP */}
            <ResultsDesktopTopBar selectedGrade={selectedGrade}
                                  onLeaveFlashPressed={onLeaveFlashPressed} />
            <ResultsDesktopTopTexts />
            <ResultsDesktopContentLayout>
                <ResultsDesktopSummaryLayout>
                    <ResultsDesktopScoreCard scores={computeFlashScores()[0]}
                                             total={questions?.length} />
                    <ResultsDesktopGraphCard scores={computeFlashScores()[0]}/>
                </ResultsDesktopSummaryLayout>
                <ResultsDesktopQuestionsCard questions={questions}
                                             selectedGrade={selectedGrade} />
            </ResultsDesktopContentLayout>
        </div>
    );

    // RENDER
    // ---- Render either graphical summary or questions list
    function _renderResultsMobileSelectedTab() {
        switch (selectedTab) {
            case 0:
                return <ResultsQuestionsList questions={questions} />
            case 1:
                return <ResultsGraphList scores={computeFlashScores()[0]} />
            default:
                return <ResultsQuestionsList questions={questions} />
        }
    }

    // METHODS
    // ---- Compute flash scores
    function computeFlashScores() {
        // Prepare
        let scores = [0, 0, 0]
        // Compute
        for (let i = 0; i < questions?.length; i++) {
            // Scores
            if (questions[i].selectedOption.isCorrect === null) {
                scores[2] += 1
            } else {
                questions[i].selectedOption.isCorrect ? scores[0] += 1 : scores[1] += 1
            }
        }
        // Return
        return [scores]
    }

}

// EXPORT
export default ResultsStepScreen