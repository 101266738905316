import QuestionsMobileLayout from "../../components/layouts/questionsStepScreen/QuestionsMobileLayout";
import QuestionsDesktopLayout
    from "../../components/layouts/questionsStepScreen/QuestionsDesktopLayout";
import QuestionsMobileTopBar from "../../components/bars/questionsStepScreen/QuestionsMobileTopBar";
import QuestionsMobileBottomBar from "../../components/bars/questionsStepScreen/QuestionsMobileBottomBar";
import QuestionsMobileCard from "../../components/cards/questionsStepScreen/QuestionsMobileCard";
import {useState} from "react";
import SystemLeaveFlashDialog from "../../components/dialogs/SystemLeaveFlashDialog";
import QuestionsDesktopTopBar from "../../components/bars/questionsStepScreen/QuestionsDesktopTopBar";
import QuestionsDesktopBottomBar from "../../components/bars/questionsStepScreen/QuestionsDesktopBottomBar";
import QuestionDontKnowDialog from "../../components/dialogs/questionsStepScreen/QuestionDontKnowDialog";
import QuestionsTabletTopBar from "../../components/bars/questionsStepScreen/QuestionsTabletTopBar";
import QuestionsCard
    from "../../components/others/questionsStepScreen/QuestionsCard";
import QuestionsTabletLayout from "../../components/layouts/questionsStepScreen/QuestionsTabletLayout";
import QuestionsTabletBottomBar from "../../components/bars/questionsStepScreen/QuestionsTabletBottomBar";
import {isArrayNullUndefinedOrEmpty} from "../../services/Validator";
import ExistingFlashSnackBar from "../../components/snackbars/ExistingFlashSnackBar";

function QuestionsStepScreen({selectedGrade,
                              questions,
                              questionIndex,
                              selectedOption,
                              setSelectedOption,
                              submitAnswer,
                              onLeaveFlashPressed,
                              setIsLeaveModalOpen,
                              isLeaveModalOpen,
                              leaveFlash,
                              isSnackbarOpen,
                              handleClose}) {
    // Used in FlashScreen Component to display series of questions

    // PROPERTIES
    const [openDontKnowDialog, setOpenDontKnowDialog] = useState(false)
    const [displayPicture, setDisplayPicture] = useState(true)
    const totalQuestions = questions?.length
    const currentQuestionIndex = questionIndex + 1

    // BUILD
    if (isArrayNullUndefinedOrEmpty(questions))
        return null
    return(
      <div className="overflow-auto animate-appear w-full h-screen bg-backgroundLight3 flex flex-col items-center justify-start">
          {/** ---- SNACKBAR */}
          <ExistingFlashSnackBar open={isSnackbarOpen}
                                 handleClose={handleClose} />
          {/** ---- MODAL */}
          <SystemLeaveFlashDialog open={isLeaveModalOpen}
                                  handleClose={() => setIsLeaveModalOpen(false)}
                                  leaveFlash={leaveFlash} />
          <QuestionDontKnowDialog open={openDontKnowDialog}
                                  handleClose={() => setOpenDontKnowDialog(false)} />
          {/** MOBILE */}
          <QuestionsMobileLayout>
              <QuestionsMobileTopBar onLeaveFlashPressed={onLeaveFlashPressed}
                                     currentQuestion={questionIndex + 1}
                                     total={questions?.length}
                                     selectedGrade={selectedGrade} />
              <QuestionsMobileCard question={questions[questionIndex]}
                                   optionSelected={selectedOption}
                                   setOptionSelected={setSelectedOption}
                                   displayPicture={displayPicture}
                                   setDisplayPicture={setDisplayPicture}
                                   setOpenDontKnowDialog={setOpenDontKnowDialog} />
              <QuestionsMobileBottomBar selectedOption={selectedOption}
                                        submitAnswer={submitAnswer} />
          </QuestionsMobileLayout>
          {/** TABLET */}
          <QuestionsTabletTopBar onLeaveFlashPressed={onLeaveFlashPressed}
                                 selectedGrade={selectedGrade}
                                 currentQuestion={currentQuestionIndex}
                                 totalQuestion={totalQuestions} />
          <QuestionsTabletLayout>
              <QuestionsCard question={questions[questionIndex]}
                             optionSelected={selectedOption}
                             setOptionSelected={setSelectedOption}
                             setOpenDontKnowDialog={setOpenDontKnowDialog} />
              <QuestionsTabletBottomBar selectedOption={selectedOption}
                                        submitAnswer={submitAnswer} />
          </QuestionsTabletLayout>
          {/** DESKTOP */}
          <QuestionsDesktopTopBar onLeaveFlashPressed={onLeaveFlashPressed}
                                  selectedGrade={selectedGrade}
                                  currentQuestion={currentQuestionIndex}
                                  totalQuestion={totalQuestions} />
          <QuestionsDesktopLayout>
              <QuestionsCard question={questions[questionIndex]}
                             optionSelected={selectedOption}
                             setOptionSelected={setSelectedOption}
                             setOpenDontKnowDialog={setOpenDontKnowDialog} />
              <QuestionsDesktopBottomBar selectedOption={selectedOption}
                                         submitAnswer={submitAnswer} />
          </QuestionsDesktopLayout>
      </div>
    );
}

// EXPORT
export default QuestionsStepScreen