import {Link} from "react-router-dom";

function SystemMailToButton({mailTo, label}) {
    // Used through the app to send a mail based on email
    // address text

    // BUILD
    return (
        <Link to='#'
              onClick={(e) => {
                window.location.href = mailTo;
                e.preventDefault();
              }} >
            <span className={"text-[16px] sm:text-[18px] font-medium text-primaryLight1 no-underline hover:underline"}>
                {label}
            </span>
        </Link>
    );
}

// EXPORT
export default SystemMailToButton