import {isNullOrUndefined} from "../../../services/Validator";

function AboutMobileItemTitleText({title}) {
    // Used in

    // BUILD
    if (isNullOrUndefined(title))
        return null
    return (
        <div className={"text-[18px] text-white items-start justify-start"}>
            <span className={"font-bold"}>
                {title}
            </span>
        </div>
    );
}

// EXPORT
export default AboutMobileItemTitleText