import ParameterMobileBackIcon from "../../icons/parameterScreen/ParameterMobileBackIcon";
import ParameterSelectedLevelAsCounter from "../parameterStepScreen/ParameterSelectedLevelAsCounter";
import ParameterSubthemeHeaderCounter from "./ParameterSubthemeHeaderCounter";
import {ScreenWidth} from "../../../enums/ScreenWidth";

function ParameterMobileLevelAsHeader({levelAsCount,
                                       selectedLevelAsCount,
                                       goBackToSubthemes}) {
    // Used in SubthemeTabletContent to display Subthemes
    // LevelAs header

    // BUILD
    return (
        <div className={"bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] w-full py-8 px-12 flex justify-between"}>
            <div className={"flex justify-start items-center space-x-4"}>
                <ParameterMobileBackIcon onClick={() => goBackToSubthemes()} />
                <ParameterSubthemeHeaderCounter text={"Savoir-faire"}
                                                count={levelAsCount} extraClass={""} />
            </div>
            <ParameterSelectedLevelAsCounter count={selectedLevelAsCount}
                                             screenWidth={ScreenWidth.SMALL} />
        </div>
    );
}

// EXPORT
export default ParameterMobileLevelAsHeader