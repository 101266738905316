import {isNullOrUndefined} from "../../../services/Validator";

function ParameterSubthemeHeaderCounter({text, count, extraClass}) {
    // Used in ParameterMobileLevelAsHeader to display selected levelAs
    // counter

    // BUILD
    if (isNullOrUndefined(text) || isNullOrUndefined(count))
        return null
    return (
        <div className={"flex justify-start items-center space-x-4 " + extraClass}>
            <span className={"text-[14px] sm:text-[16px] font-medium text-greyLight11"}>
                {text}
            </span>
            <span className={"text-[12px] sm:text-[14px] font-medium text-greyLight7"}>
                {"(" + count + ")"}
            </span>
        </div>
    );
}

// EXPORT
export default ParameterSubthemeHeaderCounter