import {isNullOrUndefined} from "../../../services/Validator";
import ParameterUnselectedGrade from "./ParameterUnselectedGrade";
import ParameterSubthemesLoader from "../../loaders/parameterStepScreen/ParameterSubthemesLoader";
import ParameterSubthemesError from "../../errors/parameterScreen/ParameterSubthemesError";
import SubthemeMobileLevelAsList from "../../lists/subthemeStepScreen/SubthemeMobileLevelAsList";
import ParameterMobileSubthemesList from "../../lists/parameterStepScreen/ParameterMobileSubthemesList";
import ParameterMobileLevelAsHeader from "./ParameterMobileLevelAsHeader";
import ParameterMobileSubthemesHeader from "./ParameterMobileSubthemesHeader";

function ParameterMobileSubthemesContent({selectedSubtheme,
                                           selectedGrade,
                                           isSubthemesLoading,
                                           hasSubthemesError,
                                           subthemes,
                                           levelAs,
                                           toggleLevelA,
                                           selectSubtheme,
                                           selectedLevelAs,
                                           displayMobileLevelAs,
                                           goBackToSubthemes}) {
    // Used in ParameterMobileContent to display Subthemes and LevelAs
    // related components in mobile mode

    // PROPERTIES
    const subthemesCount = subthemes?.length
    const levelAsCount = levelAs?.length
    const selectedLevelAsCount = selectedLevelAs?.length

    // BUILD
    if (hasSubthemesError)
        return <ParameterSubthemesError />
    if (isSubthemesLoading)
        return <ParameterSubthemesLoader />
    if (isNullOrUndefined(selectedGrade))
        return <ParameterUnselectedGrade />
    if (!displayMobileLevelAs) {
        return (
            <div className={"animate-appear overflow-auto w-full grow flex flex-col items-center pt-12"}>
                <ParameterMobileSubthemesHeader subthemesCount={subthemesCount} />
                <div className={"overflow-auto bg-backgroundLight3 w-full h-full flex bg-backgroundLight3"}>
                    <ParameterMobileSubthemesList subthemes={subthemes}
                                                  selectSubtheme={selectSubtheme}
                                                  selectedSubtheme={selectedSubtheme} />
                </div>
            </div>
        );
    } else {
        return (
            <div className={"animate-appear overflow-auto w-full grow flex flex-col items-center pt-12"}>
                <ParameterMobileLevelAsHeader levelAsCount={levelAsCount}
                                              selectedLevelAsCount={selectedLevelAsCount}
                                              goBackToSubthemes={goBackToSubthemes} />
                <div className={"overflow-auto bg-backgroundLight3 w-full h-full flex bg-backgroundLight3"}>
                    <SubthemeMobileLevelAsList levelAs={levelAs}
                                               selectedLevelAs={selectedLevelAs}
                                               toggleLevelA={toggleLevelA} />
                </div>
            </div>
        );
    }
}

// EXPORT
export default ParameterMobileSubthemesContent