import {isArrayNullUndefinedOrEmpty} from "../../../services/Validator";
import ParameterGradeBadgeItem from "../../items/parameterStepScreen/ParameterGradeBadgeItem";

function ParameterGradesList({grades,
                              selectedGrade,
                              setFlashGrade,
                              gradePickerSize,
                              extraClass}) {
    // Used in GradeMobileContent and LevelDesktopContent to
    // display list of flash levels to pick

    // BUILD
    if (isArrayNullUndefinedOrEmpty(grades))
        return null
    return (
        <div className={"w-full px-8 sm:px-0 flex items-center justify-around sm:justify-center space-x-0 sm:space-x-32 lg:space-x-40 " + extraClass}>
            {grades?.map((grade, index) => (
                <ParameterGradeBadgeItem key={index}
                                         grade={grade}
                                         selectedGrade={selectedGrade}
                                         setFlashGrade={setFlashGrade}
                                         gradePickerSize={gradePickerSize}/>
            ))}
        </div>
    );
}

// EXPORT
export default ParameterGradesList