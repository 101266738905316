import LandingMobileMasterTitleText from "../../texts/landingScreen/LandingMobileMasterTitleText";
import LandingMobileMasterSubtitleText from "../../texts/landingScreen/LandingMobileMasterSubtitleText";
import LandingMobileMasterButton from "../../buttons/landingScreen/LandingMobileMasterButton";
import LandingBearTracksButton from "../../buttons/landingScreen/LandingBearTracksButton";
import {BearTracksSize} from "../../../controllers/BearTracksButtonController";
import {kLandingTexts} from "../../../constants/texts";

function LandingMobileShowCase({onBearTracksClicked}) {
    // Used in LandingScreen to display app problem
    // in mobile mode

    // BUILD
    return (
        <div className={"w-full h-[calc(100vh-56px)] min-h-[calc(100vh-56px)] bg-backgroundLight1 flex flex-col justify-between pt-16 pb-16 px-24"}>
            <div className={"w-full grow flex flex-col space-y-32 justify-center"}>
                <div className={"w-full flex flex-col space-y-16"}>
                    <LandingMobileMasterTitleText title={kLandingTexts.punchline} />
                    <LandingMobileMasterSubtitleText subtitle={kLandingTexts.subPunchline} />
                </div>
                <LandingMobileMasterButton />
            </div>
            <LandingBearTracksButton onBearTracksClicked={onBearTracksClicked}
                                     bearTracksSize={BearTracksSize.SMALL} />
        </div>
    );
}

// EXPORT
export default LandingMobileShowCase