import {isNullOrUndefined} from "../../../services/Validator";

function LawDesktopTabletItemLayout({children}) {
    // Used in law screens to
    // frame section title and basic text

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"flex flex-col space-y-12"}>
            {children}
        </div>
    );
}

// EXPORT
export default LawDesktopTabletItemLayout