import LawMobileBulletSectionText from "../lawScreens/LawMobileBulletSectionText";
import LawMobileSectionCoreText from "../lawScreens/LawMobileSectionCoreText";
import {kLegalTexts} from "../../../constants/texts";

function LegalsMobileInfoText() {
    // Used in

    // BUILD
    return (
        <div className={"flex flex-col items-start justify-start space-y-6"}>
            <LawMobileSectionCoreText text={kLegalTexts.infoOne} />
            <LawMobileBulletSectionText text={kLegalTexts.infoTwo} />
            <LawMobileBulletSectionText text={kLegalTexts.infoThree} />
            <LawMobileBulletSectionText text={kLegalTexts.infoFour} />
        </div>
    );
}

// EXPORT
export default LegalsMobileInfoText