import LawDesktopTabletItemLayout from "../../layouts/lawScreens/LawDesktopTabletItemLayout";
import LawDesktopTabletSectionTitleText from "../../texts/lawScreens/LawDesktopTabletSectionTitleText";
import LegalsDesktopTabletInfoText from "../../texts/legalsScreen/LegalsDesktopTabletInfoText";
import LegalsDesktopTabletPublisherText from "../../texts/legalsScreen/LegalsDesktopTabletPublisherText";
import LegalsDesktopTabletHostText from "../../texts/legalsScreen/LegalsDesktopTabletHostText";
import LawDesktopTabletSectionCoreText from "../../texts/lawScreens/LawDesktopTabletSectionCoreText";
import LegalsDesktopTabletPersonalDataText from "../../texts/legalsScreen/LegalsDesktopTabletPersonalDataText";
import LegalsDesktopTabletCookieText from "../../texts/legalsScreen/LegalsDesktopTabletCookieText";
import LegalsDesktopTabletContactText from "../../texts/legalsScreen/LegalsDesktopTabletContactText";
import {kLegalTexts} from "../../../constants/texts";

function LegalsDesktopTabletContent() {
    // Used in LegalsScreen to display texts
    // in mobile mode

    // BUILD
    return (
        <div className="w-full flex flex-col space-y-24">
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={1}
                                           title={"Informations légales"} />
                <LegalsDesktopTabletInfoText />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={2}
                                           title={"Directeur de la publication"} />
                <LegalsDesktopTabletPublisherText />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={3}
                                           title={"Hébergeur"} />
                <LegalsDesktopTabletHostText />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={4}
                                           title={"Propriété intellectuelle"} />
                <LawDesktopTabletSectionCoreText text={kLegalTexts.intellectualProperty} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={5} title={"Données personnelles"} />
                <LegalsDesktopTabletPersonalDataText />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={6} title={"Cookies"} />
                <LegalsDesktopTabletCookieText />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={7} title={"Responsabilité"} />
                <LawDesktopTabletSectionCoreText text={kLegalTexts.responsibilityOne} />
                <LawDesktopTabletSectionCoreText text={kLegalTexts.responsibilityTwo} />
                <LawDesktopTabletSectionCoreText text={kLegalTexts.responsibilityThree} />
                <LawDesktopTabletSectionCoreText text={kLegalTexts.responsibilityFour} />
                <LawDesktopTabletSectionCoreText text={kLegalTexts.responsibilityFive} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={8}
                                           title={"Loi applicable et juridiction compétente"} />
                <LawDesktopTabletSectionCoreText text={kLegalTexts.law} />
            </LawDesktopTabletItemLayout>
            <LawDesktopTabletItemLayout>
                <LawDesktopTabletSectionTitleText index={9}
                                           title={"Contact"} />
                <LegalsDesktopTabletContactText />
            </LawDesktopTabletItemLayout>
        </div>
    );
}

// EXPORT
export default LegalsDesktopTabletContent