import AboutDesktopTabletSectionLayout from "../../layouts/aboutScreen/AboutDesktopTabletSectionLayout";
import AboutDesktopTabletTopTitleLayout from "../../layouts/aboutScreen/AboutDesktopTabletTopTitleLayout";
import AboutDesktopTabletContactText from "../../texts/aboutScreen/AboutDesktopTabletContactText";
import AboutImage from "../../images/aboutScreen/AboutImage";

function AboutDesktopTabletContactSection() {
    // Used in AboutDesktopTabletContent to display
    // contact section in desktop tablet modes

    // BUILD
    return (
        <AboutDesktopTabletSectionLayout>
            <AboutDesktopTabletTopTitleLayout>
                <AboutImage imageUrl={'/images/aboutScreen/mail.png'} />
                <AboutDesktopTabletContactText />
            </AboutDesktopTabletTopTitleLayout>
        </AboutDesktopTabletSectionLayout>
    );
}

// EXPORT
export default AboutDesktopTabletContactSection