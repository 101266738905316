import {isNullOrUndefined} from "../../../services/Validator";
import ParameterSubthemeHeaderCounter from "../parameterStepScreen/ParameterSubthemeHeaderCounter";
import {kSystemTexts} from "../../../constants/texts";

function ParameterMobileSubthemesHeader({subthemesCount}) {
    // Used in SubthemeTabletContent to display Subthemes
    // LevelAs header

    // BUILD
    if (isNullOrUndefined(subthemesCount))
        return null
    return (
        <div className={"bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] w-full py-8 px-12 flex"}>
            <ParameterSubthemeHeaderCounter text={kSystemTexts.themes}
                                            count={subthemesCount}
                                            extraClass={""} />
        </div>
    );
}

// EXPORT
export default ParameterMobileSubthemesHeader