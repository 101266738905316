import SystemMailToButton from "../../buttons/SystemMailToButton";
import {kAboutContactTexts, kSystemTexts} from "../../../constants/texts";

function AboutDesktopTabletContactText() {
    // Used in AboutMobileContactSection to display contact text

    // BUILD
    return (
        <div className={"text-[18px] font-medium text-white"}>
            <span className={""}>
                {kAboutContactTexts.introOne}
            </span>
            <span className={"text-white"}>
                {kAboutContactTexts.introTwo}
            </span>
            <span className={""}>
                {kAboutContactTexts.introThree}
            </span>
            <SystemMailToButton label={kSystemTexts.contactMailAddress} mailTo={`mailto:${kSystemTexts.contactMailAddress}`} />
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletContactText