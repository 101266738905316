import LawMobileLastUpdateText from "../../texts/lawScreens/LawMobileLastUpdateText";
import LawMobileTitleText from "../../texts/lawScreens/LawMobileTitleText";

function LawMobileTitle({pageTitle, lastUpdateDate, isLegals}) {
    // Used in LegalsScreen to display screen title text
    // with last update date

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-6 items-start leading-none"}>
            <LawMobileTitleText pageTitle={pageTitle} />
            <LawMobileLastUpdateText lastUpdateDate={lastUpdateDate}
                                     isLegals={isLegals} />
        </div>
    );
}

// EXPORT
export default LawMobileTitle