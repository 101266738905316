import {useState} from "react";
import {isNullOrUndefined} from "../../../services/Validator";
import {
    isSubthemeSelected,
    renderSubthemeStyle,
    renderSubthemeThemeStyle
} from "../../../controllers/SubthemeItemController";
import {capitalizeFirst} from "../../../helpers/StringHelper";
import {get_nested} from "../../../services/JsonHandler";
import SystemIcon from "../../icons/SystemIcon";
import SystemCustomCheckBox from "../../others/SystemCustomCheckBox";

function ParameterSubthemeItem({subtheme, selectSubtheme, selectedSubtheme}) {
    // Used in LevelDesktopSchoolLevelsList to display one single
    // level card

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)
    const subthemeName = get_nested(['name'], subtheme, null)
    const subthemeThemeName = get_nested(['subthemeTheme', 'name'], subtheme, null)

    // BUILD
    if (isNullOrUndefined(subtheme) || isNullOrUndefined(subthemeName))
        return null
    return (
        <div className={`w-full flex justify-between items-center cursor-pointer px-12 lg:px-16 py-8 lg:py-12 border-b-1 border-backgroundLight5`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={() => selectSubtheme(subtheme)}>
            <div className={"flex justify-start items-start space-x-8"}>
                <SystemCustomCheckBox isChecked={isSubthemeSelected(subtheme, selectedSubtheme)} />
                <div className={"flex flex-col font-medium"}>
                    <span className={`text-[16px] ${renderSubthemeStyle(subtheme, selectedSubtheme, isHovered)}`}>
                        {capitalizeFirst(subthemeName)}
                    </span>
                    <span className={`text-[14px] ${renderSubthemeThemeStyle(subtheme, selectedSubtheme, isHovered)}`}>
                        {capitalizeFirst(subthemeThemeName)}
                    </span>
                </div>
            </div>
            <SystemIcon icon={"chevron_right"}
                        isHovered={isHovered} />
        </div>
    );
}

// EXPORT
export default ParameterSubthemeItem