
function ParameterMobileBackIcon({onClick}) {
    // Used in ParameterMobileLevelAsHeader to go back to previous view

    // BUILD
    return (
        <div className={"cursor-pointer w-[24px] min-w-[24px] h-[24px] min-h-[24px] -ml-6 mt-2 flex items-center"}
             onClick={onClick}>
            <i className={`material-icons text-[24px] text-greyLight11`}>
                {"chevron_left"}
            </i>
        </div>
    );
}

// EXPORT
export default ParameterMobileBackIcon