import {isNullOrUndefined} from "../../services/Validator";

function SystemFlashStepSubtitleText({text, extraClass}) {
    // Used through the app to display top step progress
    // bar title text in desktop mode

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div>
            <span className={"w-full flex items-center justify-center text-[16px] sm:text-[20px] lg:text-[22px] text-center text-white font-medium " + extraClass}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default SystemFlashStepSubtitleText