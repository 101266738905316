import {kSystemIcons} from "../../../constants/icons";

function ParameterSubthemesVoid() {
    // Used in ParameterSubthemesList to display
    // empty Subthemes list

    // BUILD
    return (
        <div className={"animate-appear w-full h-full flex flex-col justify-center items-center shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[0px] sm:rounded-[16px] px-16 mt-16 sm:mt-24"}>
            <i className={`material-icons text-[24px] lg:text-[32px] text-greyLight7`}>
                {kSystemIcons.support}
            </i>
            <span className={"font-normal text-center text-[16px] lg:text-[18px] text-greyLight7"}>
                {"Aucun thème n'est actuellement disponible pour ce niveau, tu peux essayer de sélectionner un autre niveau"}
            </span>
        </div>
    );
}

// EXPORT
export default ParameterSubthemesVoid