import {renderScoreEmoji} from "../../../controllers/ResultsScoreEmojiImageController";
import {isNullOrUndefined} from "../../../services/Validator";

function ResultsScoreEmojiImage({successRatio, openAppreciation}) {
    // Used in ResultsScore to show bear emoji related
    // to total score

    // BUILD
    if (isNullOrUndefined(successRatio))
        return null;
    return (
        <div className="cursor-pointer w-[80px] lg:w-[96px] min-w-[80px] lg:min-w-[96px]"
             onClick={() => openAppreciation()}>
            <img src={renderScoreEmoji(successRatio)} alt={"Emotion"}/>
        </div>
    );
}

// EXPORT
export default ResultsScoreEmojiImage