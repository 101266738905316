import LawContentMobileLayout from "../components/layouts/lawScreens/LawContentMobileLayout";
import LawMobileTitle from "../components/others/lawScreens/LawMobileTitle";
import LawDesktopTabletLayout from "../components/layouts/lawScreens/LawDesktopTabletLayout";
import LawContentDesktopTabletContentLayout
    from "../components/layouts/lawScreens/LawContentDesktopTabletContentLayout";
import LawDesktopTabletTitle from "../components/others/lawScreens/LawDesktopTabletTitle";
import LegalsMobileContent from "../components/others/legalsScreen/LegalsMobileContent";
import LegalsDesktopTabletContent from "../components/others/legalsScreen/LegalsDesktopTabletContent";
import LawMobileTopBar from "../components/bars/lawScreens/LawMobileTopBar";
import LawTabletTopBar from "../components/bars/lawScreens/LawTabletTopBar";
import LawDesktopTopBar from "../components/bars/lawScreens/LawDesktopTopBar";
import {kLegalTexts, kSystemTexts} from "../constants/texts";

function LegalsScreen() {
    // Used in index by the router as an entry point
    // for compulsory legal texts

    // BUILD
    return (
        <div className={"overflow-auto w-full h-screen min-h-screen flex flex-col items-center justify-start"}>
            {/** MOBILE */}
            <LawMobileTopBar />
            <LawContentMobileLayout>
                <LawMobileTitle pageTitle={kLegalTexts.title}
                                lastUpdateDate={kSystemTexts.legalDocumentsLastUpdate}
                                isLegals={true} />
                <LegalsMobileContent />
            </LawContentMobileLayout>
            {/** TABLET + DESKTOP */}
            <LawTabletTopBar />
            <LawDesktopTopBar />
            <LawDesktopTabletLayout>
                <LawContentDesktopTabletContentLayout>
                    <LawDesktopTabletTitle pageTitle={kLegalTexts.title}
                                           lastUpdateDate={kSystemTexts.legalDocumentsLastUpdate}
                                           isLegals={true} />
                    <LegalsDesktopTabletContent />
                </LawContentDesktopTabletContentLayout>
            </LawDesktopTabletLayout>
        </div>
    );
}

// EXPORT
export default LegalsScreen