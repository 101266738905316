import {isNullOrUndefined} from "../../../services/Validator";

function PreviewQuestionDesktopLayout({children}) {
    // Used in QuestionsStepScreen to frame screen content in
    // desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"hidden grow w-[960px] lg:flex lg:flex-col items-center justify-start mt-32"}>
            {children}
        </div>
    );
}

// EXPORT
export default PreviewQuestionDesktopLayout