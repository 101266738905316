import {kAboutMissionTexts} from "../../../constants/texts";

function AboutDesktopTabletMissionConclusionText({goToMethod}) {
    // Used in AboutMobileMissionSection to display conclusion

    // BUILD
    return (
        <div className={"whitespace-pre-line text-[18px] font-medium"}>
            <span className={"text-white"}>
                {kAboutMissionTexts.outroOne}
            </span>
            <span className={"text-primaryLight1 cursor-pointer hover:underline"}
                  onClick={goToMethod}>
                {kAboutMissionTexts.outroTwo}
            </span>
            <span className={"text-white"}>
                {kAboutMissionTexts.outroThree}
            </span>
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletMissionConclusionText