import {Switch} from "@mui/material";
import SystemMobileTopBarLayout from "../../layouts/SystemMobileTopBarLayout";
import SystemMobileFlashToHomeButton from "../../buttons/SystemMobileFlashToHomeButton";
import PreviewLogoImage from "../../images/previewQuestionScreen/PreviewLogoImage";
import {kPreviewSwitchStyle} from "../../../constants/styles";
import SystemVerticalMiniDivider from "../../others/SystemVerticalMiniDivider";

function PreviewQuestionMobileTopBar({goBackToHome, displayCorrection, toggleView}) {
    // Used in PreviewQuestionScreen to display top bar in mobile mode
    // made of logo, switch and back to home button

    // PROPERTIES

    // BUILD
    return (
        <SystemMobileTopBarLayout extraClass={"border-b-1 border-greyLight3"}>
            <PreviewLogoImage />
            <div className={"flex justify-end items-center space-x-10 sm:space-x-12"}>
                <div className={"flex justify-end items-center space-x-2 sm:space-x-4 font-medium text-[14px]"}>
                    <span className={`${displayCorrection ? "text-greyLight7" : "text-greyLight11 font-semibold"}`}>
                        Flash
                    </span>
                    <Switch value={displayCorrection}
                            onChange={() => toggleView()}
                            sx={kPreviewSwitchStyle} />
                    <span className={`${displayCorrection ? "text-greyLight11 font-semibold" : "text-greyLight7"}`}>
                        Correction
                    </span>
                </div>
                <SystemVerticalMiniDivider />
                <SystemMobileFlashToHomeButton goBackToHome={goBackToHome} />
            </div>
        </SystemMobileTopBarLayout>
    );
}

// EXPORT
export default PreviewQuestionMobileTopBar