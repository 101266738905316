
function AboutMobileTopTitleLayout({children}) {
    // Used in about screen to frame section top title

    // BUILD
    if (!children)
        return null;
    return (
        <div className={"w-full flex flex-col items-center space-y-12"}>
            {children}
        </div>
    );
}

// EXPORT
export default AboutMobileTopTitleLayout