import {isNullOrUndefined} from "../../../services/Validator";

function PreviewQuestionTabletLayout({children}) {
    // Used in QuestionsStepScreen to frame screen content in
    // desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"hidden sm:w-[584px] md:w-[708px] grow sm:flex sm:flex-col lg:hidden items-center justify-start mt-32"}>
            {children}
        </div>
    );
}

// EXPORT
export default PreviewQuestionTabletLayout