import AboutMobileMissionValuesText from "../../texts/aboutScreen/AboutMobileMissionValuesText";
import AboutMobileMissionConclusionText from "../../texts/aboutScreen/AboutMobileMissionConclusionText";
import AboutMobileSectionLayout from "../../layouts/aboutScreen/AboutMobileSectionLayout";
import AboutMobileSectionTitleText from "../../texts/aboutScreen/AboutMobileSectionTitleText";
import {kAboutNavigation} from "../../../constants/navigation";
import AboutMobileSectionCoreText from "../../texts/aboutScreen/AboutMobileSectionCoreText";
import AboutMobileBulletSectionText from "../../texts/aboutScreen/AboutMobileBulletSectionText";
import AboutImage from "../../images/aboutScreen/AboutImage";
import {kAboutMissionTexts} from "../../../constants/texts";


function AboutMobileMissionSection({selectedTab, goToMethod}) {
    // Used in AboutScreen to show app mission details

    // BUILD
    return(
        <AboutMobileSectionLayout>
            <AboutMobileSectionTitleText pageTitle={kAboutNavigation[selectedTab].title}/>
            <AboutImage imageUrl={'/images/aboutScreen/mountain.png'} />
            <AboutMobileSectionCoreText text={kAboutMissionTexts.intro} />
            <AboutMobileMissionValuesText />
            <div className={"w-full flex flex-col items-start justify-start space-y-6"}>
                <AboutMobileSectionCoreText text={kAboutMissionTexts.mission} />
                <AboutMobileBulletSectionText text={kAboutMissionTexts.missionOne} />
                <AboutMobileBulletSectionText text={kAboutMissionTexts.missionTwo} />
                <AboutMobileBulletSectionText text={kAboutMissionTexts.missionThree} />
            </div>
            <AboutMobileMissionConclusionText goToMethod={goToMethod} />
        </AboutMobileSectionLayout>
    );
}

// EXPORT
export default AboutMobileMissionSection