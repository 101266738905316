import {isNullOrUndefined} from "../../../services/Validator";

function AboutDesktopTabletItemTitleText({title}) {
    // Used in AboutDesktopTabletMethodSection to display section title
    // text in desktop / tablet modes

    // BUILD
    if (isNullOrUndefined(title))
        return null
    return (
        <div className={"text-[20px] text-white items-start justify-start"}>
            <span className={"font-bold"}>
                {title}
            </span>
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletItemTitleText