
function LawMobileSectionCoreText({text}) {
    // Used in Legals and Privacy screen to display single section
    // text under section title

    // BUILD
    if (!text)
        return null
    return (
        <div className={"whitespace-pre-line"}>
            <span className={"text-[16px] font-medium text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default LawMobileSectionCoreText