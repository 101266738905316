import SystemMailToButton from "../../buttons/SystemMailToButton";
import {kLegalTexts, kSystemTexts} from "../../../constants/texts";

function LegalsMobileContactText() {
    // Used in LegalsMobileContent to display contact text
    // in mobile mode

    // BUILD
    return (
        <div className={"text-[16px] font-medium"}>
            <span className={"text-white"}>
                {kLegalTexts.contact}
            </span>
            <SystemMailToButton label={kSystemTexts.contactMailAddress}
                                mailTo={`mailto:${kSystemTexts.contactMailAddress}`} />
        </div>
    );
}

// EXPORT
export default LegalsMobileContactText