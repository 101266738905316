import AboutMobileContactText from "../../texts/aboutScreen/AboutMobileContactText";
import AboutMobileSectionLayout from "../../layouts/aboutScreen/AboutMobileSectionLayout";
import {kAboutNavigation} from "../../../constants/navigation";
import AboutMobileSectionTitleText from "../../texts/aboutScreen/AboutMobileSectionTitleText";
import AboutMobileTopTitleLayout from "../../layouts/aboutScreen/AboutMobileTopTitleLayout";
import AboutImage from "../../images/aboutScreen/AboutImage";

function AboutMobileContactSection({selectedTab}) {
    // Used in AboutScreen to show contact details

    // BUILD
    return (
        <AboutMobileSectionLayout>
            <AboutMobileSectionTitleText pageTitle={kAboutNavigation[selectedTab].title} />
            <AboutMobileTopTitleLayout>
                <AboutImage imageUrl={'/images/aboutScreen/mail.png'} />
                <AboutMobileContactText />
            </AboutMobileTopTitleLayout>
        </AboutMobileSectionLayout>
    );
}

// EXPORT
export default AboutMobileContactSection