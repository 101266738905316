import {isNullOrUndefined} from "../../../services/Validator";

function AboutImage({imageUrl}) {
    // Used in AboutScreen to display main section image
    // in all mobile, tablet and desktop modes

    // BUILD
    if (isNullOrUndefined(imageUrl))
        return null
    return (
        <div className={`h-[96px] min-h-[96px] sm:h-[120px] sm:min-h-[120px] lg:h-[136px] lg:min-h-[136px] flex justify-center items-center`}>
            <img className={"object-contain w-full h-full"} src={imageUrl} alt="" />
        </div>
    );
}

// EXPORT
export default AboutImage