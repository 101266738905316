import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {routes} from "../../config/routes";
import {useNavigate} from "react-router-dom";
import {isNullOrUndefined} from "../../services/Validator";
import {get_single_question} from "../../api/question";
import {isNumber} from "chart.js/helpers";
import QuestionsCard from "../../components/others/questionsStepScreen/QuestionsCard";
import PreviewQuestionScreenError from "../../components/errors/previewQuestionScreen/PreviewQuestionScreenError";
import SystemScreenLoader from "../../components/loaders/SystemScreenLoader";
import PreviewQuestionDesktopTopBar from "../../components/bars/previewQuestionScreen/PreviewQuestionDesktopTopBar";
import CorrectionDesktopContent from "../../components/others/correctionStepScreen/CorrectionDesktopContent";
import PreviewQuestionDesktopLayout from "../../components/layouts/previewQuestionScreen/PreviewQuestionDesktopLayout";
import PreviewQuestionTabletLayout from "../../components/layouts/previewQuestionScreen/PreviewQuestionTabletLayout";
import PreviewQuestionTabletTopBar from "../../components/bars/previewQuestionScreen/PreviewQuestionTabletTopBar";
import CorrectionTabletContent from "../../components/others/correctionStepScreen/CorrectionTabletContent";
import PreviewQuestionMobileLayout from "../../components/layouts/previewQuestionScreen/PreviewQuestionMobileLayout";
import QuestionsMobileCard from "../../components/cards/questionsStepScreen/QuestionsMobileCard";
import PreviewQuestionMobileTopBar from "../../components/bars/previewQuestionScreen/PreviewQuestionMobileTopBar";
import CorrectionMobileContent from "../../components/others/correctionStepScreen/CorrectionMobileContent";

function PreviewQuestionScreen() {
    // Used in index.js to preview one specific Question in both Flash and Correction views

    // PROPERTIES
    let navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [displayCorrection, setDisplayCorrection] = useState(false)
    const [question, setQuestion] = useState(null)


    // HOOKS
    // ----  Memorizes a function, preventing it from being redefined on every render, unless its dependencies change.
    const getSingleQuestion = useCallback(() => {
        if (isNullOrUndefined(id) || !isNumber(id) || id < 0)
            return
        // Set loader on and clear potential error
        setIsLoading(true)
        setHasError(false)
        // Perform
        get_single_question(id)
            .then((res) => {
                setQuestion(res)
                setHasError(false)
            }).catch((err) => {
            setHasError(true)
            console.error(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [id])
    // ---- Triggered at rendering : get single Question only in dev and staging env
    useEffect(() => {
        getSingleQuestion()
    }, [getSingleQuestion])

    // BUILD
    if (hasError)
        return <PreviewQuestionScreenError goBackToHome={() => {navigate(routes.landing)}}
                                           retryFetching={getSingleQuestion} />
    if (isLoading)
        return <SystemScreenLoader />
    return (
        <div className="overflow-auto animate-appear w-full h-screen bg-backgroundLight3 flex flex-col items-center justify-start">
            {/** MOBILE */}
            <PreviewQuestionMobileTopBar goBackToHome={() => navigate(routes.landing)}
                                         displayCorrection={displayCorrection}
                                         toggleView={toggleView} />
            <PreviewQuestionMobileLayout>
                {displayCorrection
                    ? <CorrectionMobileContent question={question}
                                               handleClose={null}/>
                    : <QuestionsMobileCard question={question}
                                           optionSelected={null}
                                           setOptionSelected={null}
                                           displayPicture={false}
                                           setDisplayPicture={null}
                                           setOpenDontKnowDialog={null} />
                }
            </PreviewQuestionMobileLayout>
            {/** TABLET */}
            <PreviewQuestionTabletTopBar goBackToHome={() => navigate(routes.landing)}
                                         displayCorrection={displayCorrection}
                                         toggleView={toggleView} />
            <PreviewQuestionTabletLayout>
                {displayCorrection
                    ? <CorrectionTabletContent question={question} />
                    : <QuestionsCard question={question}
                                     optionSelected={null}
                                     setOptionSelected={null}
                                     setOpenDontKnowDialog={null} />
                }
            </PreviewQuestionTabletLayout>
            {/** DESKTOP */}
            <PreviewQuestionDesktopTopBar goBackToHome={() => navigate(routes.landing)}
                                          displayCorrection={displayCorrection}
                                          toggleView={toggleView}/>
            <PreviewQuestionDesktopLayout>
                {displayCorrection
                    ? <CorrectionDesktopContent question={question} />
                    : <QuestionsCard question={question}
                                     optionSelected={null}
                                     setOptionSelected={null}
                                     setOpenDontKnowDialog={null} />
                }
            </PreviewQuestionDesktopLayout>
        </div>
    );

    // METHODS
    // ---- Toggle view to display
    function toggleView() {
        setDisplayCorrection(!displayCorrection)
    }
}

// EXPORT
export default PreviewQuestionScreen