import AboutDesktopTabletMissionSection from "./AboutDesktopTabletMissionSection";
import AboutDesktopTabletMethodSection from "./AboutDesktopTabletMethodSection";
import AboutDesktopTabletTeamSection from "./AboutDesktopTabletTeamSection";
import AboutDesktopTabletContactSection from "./AboutDesktopTabletContactSection";

function AboutDesktopTabletContent({selectedTab, setSelectedTab}) {
    // Used in AboutScreen to display content in desktop / tablet mode

    // BUILD
    return (
        <div className={"hidden sm:block animate-appear bg-backgroundLight3 w-full grow"}>
            {_renderSelectedTab()}
        </div>
    );

    // ---- Render selected tab content
    function _renderSelectedTab() {
        switch (selectedTab) {
            case (0):
                return <AboutDesktopTabletMissionSection goToMethod={() => setSelectedTab(1)} />
            case (1):
                return <AboutDesktopTabletMethodSection />
            case (2):
                return <AboutDesktopTabletTeamSection />
            case (3):
                return <AboutDesktopTabletContactSection />
            default :
                return <AboutDesktopTabletMissionSection goToMethod={() => setSelectedTab(1)} />
        }
    }
}

// EXPORT
export default AboutDesktopTabletContent