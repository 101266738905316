import {isNullOrUndefined} from "../../services/Validator";

function SystemFullScreenCenteredLayout({children, extraClass}) {
    // Used through the app to frame content and center it at the middle
    // of the screen

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"w-screen h-screen flex items-center justify-center " + extraClass}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemFullScreenCenteredLayout