import {renderDateText} from "../../../controllers/LawLastUpdateTextController";
import {isNullOrUndefined} from "../../../services/Validator";

function LawMobileLastUpdateText({lastUpdateDate, isLegals}) {
    // Used in legals and privacy screen to display text
    // last update date

    // BUILD
    if (isNullOrUndefined(lastUpdateDate))
        return null
    return (
        <div className={"mt-12 "}>
            <span className={"text-[12px] font-normal text-greyLight5"}>
                {renderDateText(lastUpdateDate, isLegals)}
            </span>
        </div>
    );
}

// EXPORT
export default LawMobileLastUpdateText