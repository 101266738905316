import {kSystemIcons} from "../../constants/icons";

function SystemMobileFlashToHomeButton({goBackToHome}) {
    // Used through the app to display logout button
    // in flash

    // BUILD
    return (
        <div className={"flex items-center cursor-pointer text-greyLight7 hover:text-greyLight11 -mr-2"}
             onClick={() => goBackToHome()}>
            <i className="material-icons text-[24px]">{kSystemIcons.logout}</i>
        </div>
    );
}

// EXPORT
export default SystemMobileFlashToHomeButton