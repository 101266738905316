import {useState} from "react";
import AboutTabletTabBarItemText from "../../texts/aboutScreen/AboutTabletTabBarItemText";


function AboutTabletTabBarItem({item, isSelected, setSelectedTab}) {
    // Used in AboutTabletTabBar to display one single element
    // in tablet mode

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    return (
        <div className={`sm:cursor-pointer px-2 h-[40px] w-full flex items-center justify-start ${isSelected ? "border-b-2 border-greyLight11" : ""}`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={setSelectedTab}>
            <AboutTabletTabBarItemText text={item.smallTitle}
                                       isSelected={isSelected}
                                       isHovered={isHovered} />
        </div>
    );
}

// EXPORT
export default AboutTabletTabBarItem