import {isNullOrUndefined} from "../../../services/Validator";

function LawMobileBulletSectionText({text}) {
    // Used in AboutScreen to show bullet list text

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"text-[16px] font-medium text-white flex space-x-8 items-start justify-start"}>
            <div className={"h-[8px] min-h-[8px] w-[8px] min-w-[8px] bg-white rounded-full mt-9"}></div>
            <span>{text}</span>
        </div>
    );
}

// EXPORT
export default LawMobileBulletSectionText