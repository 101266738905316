import {Link} from "react-router-dom";
import {routes} from "../../../config/routes";
import {kLegalTexts} from "../../../constants/texts";

function LegalsMobileCookieText() {
    // Used in LegalsMobileScreen to display legal text
    // about cookie

    // BUILD
    return (
        <div className={"text-[16px] font-medium text-white"}>
            <span className={""}>
                {kLegalTexts.cookie}
            </span>
            <Link to={routes.privacy}>
                <span className='text-primaryLight1 hover:underline'>
                    {kLegalTexts.privacyLink}
                </span>
            </Link>
        </div>
    );
}

// EXPORT
export default LegalsMobileCookieText