import {isNullOrUndefined} from "../../services/Validator";

function SystemIcon({icon, isHovered, extraClass}) {
    // Used through the app to display a specific icon

    // BUILD
    if (isNullOrUndefined(icon))
        return null
    return (
        <div className={"w-[24px] min-w-[24px] h-[24px] min-h-[24px] leading-none flex items-center justify-end " + extraClass}>
            <i className={`-mr-8 material-icons text-[24px] ${isHovered ? "text-white" : "text-greyLight7"}`}>
                {icon}
            </i>
        </div>
    );
}

// EXPORT
export default SystemIcon