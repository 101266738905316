

// Used through the app to handle logo text component logic

// PROPERTIES
export const FlashLogoTextSize = {
    XSMALL: "xsmall",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
}

// METHODS
// ---- Render logo text image size
export function renderFlashLogoTextSize(flashLogoTextSize) {
    switch(flashLogoTextSize) {
        case FlashLogoTextSize.XSMALL:
            return "h-[24px] min-h-[24px] max-h-[24px]"
        case FlashLogoTextSize.SMALL:
            return "h-[32px] min-h-[32px] max-h-[32px]"
        case FlashLogoTextSize.MEDIUM:
            return "h-[40px] min-h-[40px] max-h-[40px]"
        case FlashLogoTextSize.LARGE:
            return "h-[48px] min-h-[48px] max-h-[48px]"
        default:
            return "h-[32px] min-h-[32px] max-h-[32px]"
    }
}