import {isArrayEmpty, isNullOrUndefined} from "../../../services/Validator";
import ParameterLevelAsVoid from "../../voids/parameterStepScreen/ParameterLevelAsVoid";
import ParameterLevelAItem from "../../items/parameterStepScreen/ParameterLevelAItem";

function ParameterLevelAsList({levelAs, selectedLevelAs, toggleLevelA}) {
    // Used in GradeMobileContent and LevelDesktopContent to
    // display list of flash levels to pick

    // BUILD
    if (isNullOrUndefined(levelAs))
        return null
    if (isArrayEmpty(levelAs))
        return <ParameterLevelAsVoid/>
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full flex flex-col justify-start items-center"}>
            {levelAs?.map((levelA, index) => (
                <ParameterLevelAItem key={index}
                                     levelA={levelA}
                                     selectedLevelAs={selectedLevelAs}
                                     toggleLevelA={toggleLevelA}/>
            ))}
        </div>
    );
}

// EXPORT
export default ParameterLevelAsList