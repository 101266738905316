import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";


// List of methods triggering Subthemes related api calls

// ---- Get Subthemes with LevelAs from Grade
export function get_subthemes_with_levelAs_from_grade(id) {
    const url = api.domain + generatePath(api.routes.subthemesWithLevelAsForGrade, {id})
    return makeRequest(url, Methods.GET, null)
}

// ---- Get Subthemes with LevelAs from Grade and Theme
// export function get_subthemes_with_levelAs_from_grade_and_theme(data) {
//     const url = api.domain + generatePath(api.routes.subthemesWithLevelAsForGradeAndTheme)
//     return makeRequest(url, Methods.POST, data)
// }