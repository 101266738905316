import {isNullOrUndefined} from "../../../services/Validator";

function AboutDesktopTabletSectionLayout({children}) {
    // Used in

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full flex flex-col justify-start items-center space-y-32 pb-24 pt-40"}>
            {children}
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletSectionLayout