import {kAboutMissionTexts} from "../../../constants/texts";

function AboutMobileMissionValuesText() {
    // Used in AboutMobileMissionSection to display values text

    // BUILD
    return (
        <div className={"whitespace-pre-line  text-[16px] font-medium"}>
            <span className={"text-white"}>
                {kAboutMissionTexts.valuesOne}
            </span>
            <span className={"text-white"}>
                {kAboutMissionTexts.valuesTwo}
            </span>
            <span className={"text-white"}>
                {kAboutMissionTexts.valuesThree}
            </span>
        </div>
    );
}

// EXPORT
export default AboutMobileMissionValuesText