
function AboutDesktopTabletPageTitleText() {
    // Used in AboutScreen to display page title text
    // in tablet / desktop modes

    // BUILD
    return (
        <div className={"w-full flex justify-start mt-32"}>
            <span className={"text-[24px] text-white font-bold"}>
                {"À propos"}
            </span>
        </div>
    );
}

// EXPORT
export default AboutDesktopTabletPageTitleText