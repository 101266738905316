import {isArrayEmpty, isNullOrUndefined} from "../../../services/Validator";
import ParameterLevelAsVoid from "../../voids/parameterStepScreen/ParameterLevelAsVoid";
import ParameterMobileLevelAItem from "../../items/parameterStepScreen/ParameterMobileLevelAItem";

function SubthemeMobileLevelAsList({levelAs, selectedLevelAs, toggleLevelA}) {
    // Used in GradeMobileContent and LevelDesktopContent to
    // display list of flash levels to pick

    // BUILD
    if (isNullOrUndefined(levelAs))
        return null
    if (isArrayEmpty(levelAs))
        return <ParameterLevelAsVoid/>
    return (
        <div className={"w-full grow flex flex-col justify-start items-center"}>
            {levelAs?.map((levelA, index) => (
                <ParameterMobileLevelAItem key={index}
                                           levelA={levelA}
                                           selectedLevelAs={selectedLevelAs}
                                           toggleLevelA={toggleLevelA}/>
            ))}
        </div>
    );
}

// EXPORT
export default SubthemeMobileLevelAsList