import LegalsMobileInfoText from "../../texts/legalsScreen/LegalsMobileInfoText";
import LegalsMobilePublisherText from "../../texts/legalsScreen/LegalsMobilePublisherText";
import LegalsMobileHostText from "../../texts/legalsScreen/LegalsMobileHostText";
import LegalsMobilePersonalDataText from "../../texts/legalsScreen/LegalsMobilePersonalDataText";
import LegalsMobileCookieText from "../../texts/legalsScreen/LegalsMobileCookieText";
import LegalsMobileContactText from "../../texts/legalsScreen/LegalsMobileContactText";
import LawMobileItemLayout from "../../layouts/lawScreens/LawMobileItemLayout";
import LawMobileSectionTitleText from "../../texts/lawScreens/LawMobileSectionTitleText";
import LawMobileSectionCoreText from "../../texts/lawScreens/LawMobileSectionCoreText";
import {kLegalTexts} from "../../../constants/texts";

function LegalsMobileContent() {
    // Used in LegalsScreen to display texts
    // in mobile mode

    // BUILD
    return (
        <div className="w-full flex flex-col space-y-24">
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={1}
                                           title={"Informations légales"} />
                <LegalsMobileInfoText />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={2}
                                           title={"Directeur de la publication"} />
                <LegalsMobilePublisherText />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={3}
                                           title={"Hébergeur"} />
                <LegalsMobileHostText />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={4}
                                           title={"Propriété intellectuelle"} />
                <LawMobileSectionCoreText text={kLegalTexts.intellectualProperty} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={5} title={"Données personnelles"} />
                <LegalsMobilePersonalDataText />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={6} title={"Cookies"} />
                <LegalsMobileCookieText />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={7} title={"Responsabilité"} />
                <LawMobileSectionCoreText text={kLegalTexts.responsibilityOne} />
                <LawMobileSectionCoreText text={kLegalTexts.responsibilityTwo} />
                <LawMobileSectionCoreText text={kLegalTexts.responsibilityThree} />
                <LawMobileSectionCoreText text={kLegalTexts.responsibilityFour} />
                <LawMobileSectionCoreText text={kLegalTexts.responsibilityFive} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={8}
                                           title={"Loi applicable et juridiction compétente"} />
                <LawMobileSectionCoreText text={kLegalTexts.law} />
            </LawMobileItemLayout>
            <LawMobileItemLayout>
                <LawMobileSectionTitleText index={9}
                                           title={"Contact"} />
                <LegalsMobileContactText />
            </LawMobileItemLayout>
        </div>
    );
}

// EXPORT
export default LegalsMobileContent