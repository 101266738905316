import AboutMobileSectionTopTitleText from "../../texts/aboutScreen/AboutMobileSectionTopTitleText";
import AboutMobileSectionLayout from "../../layouts/aboutScreen/AboutMobileSectionLayout";
import AboutMobileTopTitleLayout from "../../layouts/aboutScreen/AboutMobileTopTitleLayout";
import {kAboutNavigation} from "../../../constants/navigation";
import AboutMobileSectionTitleText from "../../texts/aboutScreen/AboutMobileSectionTitleText";
import AboutMobileSectionCoreText from "../../texts/aboutScreen/AboutMobileSectionCoreText";
import AboutMobileItemTitleText from "../../texts/aboutScreen/AboutMobileItemTitleText";
import AboutMobileItemLayout from "../../layouts/aboutScreen/AboutMobileItemLayout";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import AboutImage from "../../images/aboutScreen/AboutImage";
import {kAboutTeamTexts} from "../../../constants/texts";


function AboutMobileTeamSection({selectedTab}) {
    // Used in AboutScreen to show app mission details

    // BUILD
    return(
        <AboutMobileSectionLayout>
            <AboutMobileSectionTitleText pageTitle={kAboutNavigation[selectedTab].title}/>
            {/** STATEMENT */}
            <AboutMobileTopTitleLayout>
                <AboutImage imageUrl={'/images/aboutScreen/teacher.png'} />
                <AboutMobileSectionTopTitleText title={kAboutTeamTexts.title} />
            </AboutMobileTopTitleLayout>
            <SystemCustomDivider color={'bg-greyLight5'} />
            {/** DETAILS */}
            <AboutMobileSectionCoreText text={kAboutTeamTexts.intro} />
            <AboutMobileItemLayout>
                <AboutMobileItemTitleText title={kAboutTeamTexts.oneName} />
                <AboutMobileSectionCoreText text={kAboutTeamTexts.one} />
            </AboutMobileItemLayout>
            <AboutMobileItemLayout>
                <AboutMobileItemTitleText title={kAboutTeamTexts.twoName} />
                <AboutMobileSectionCoreText text={kAboutTeamTexts.two} />
            </AboutMobileItemLayout>
            <AboutMobileItemLayout>
                <AboutMobileItemTitleText title={kAboutTeamTexts.threeName} />
                <AboutMobileSectionCoreText text={kAboutTeamTexts.three} />
            </AboutMobileItemLayout>
            <AboutMobileItemLayout>
                <AboutMobileItemTitleText title={kAboutTeamTexts.fourName} />
                <AboutMobileSectionCoreText text={kAboutTeamTexts.four} />
            </AboutMobileItemLayout>
        </AboutMobileSectionLayout>
    );
}

// EXPORT
export default AboutMobileTeamSection