import {isNullOrUndefined} from "../../../services/Validator";

function AboutMobileSectionCoreText({text}) {
    // Used in

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"whitespace-pre-line"}>
            <span className={"text-[16px] font-medium text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default AboutMobileSectionCoreText