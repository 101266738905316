import {isArrayNullUndefinedOrEmpty} from "../../../services/Validator";
import ParameterSubthemeItem from "../../items/parameterStepScreen/ParameterSubthemeItem";

function ParameterSubthemesList({subthemes, selectSubtheme, selectedSubtheme}) {
    // Used in GradeMobileContent and LevelDesktopContent to
    // display list of flash levels to pick

    // BUILD
    if (isArrayNullUndefinedOrEmpty(subthemes))
        return null
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-[266px] min-w-[266px] lg:w-[320px] lg:min-w-[320px] flex flex-col items-start justify-start border-r-1 border-backgroundLight5"}>
            {subthemes?.map((subtheme, index) => (
                <ParameterSubthemeItem key={index}
                                       subtheme={subtheme}
                                       selectSubtheme={selectSubtheme}
                                       selectedSubtheme={selectedSubtheme} />
            ))}
        </div>
    );
}

// EXPORT
export default ParameterSubthemesList