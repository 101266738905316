
function LawMobileItemLayout({children}) {
    // Used in side content screens to frame section title and basic text

    // BUILD
    return (
        <div className={"flex flex-col space-y-8"}>
            {children}
        </div>
    );
}

// EXPORT
export default LawMobileItemLayout