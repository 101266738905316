import LawDesktopTabletLastUpdateText from "../../texts/lawScreens/LawDesktopTabletLastUpdateText";
import LawDesktopTabletTitleText from "../../texts/lawScreens/LawDesktopTabletTitleText";

function LawDesktopTabletTitle({pageTitle, lastUpdateDate, isLegals}) {
    // Used in both Legals and Privacy screens to display
    // page title in both desktop and tablet mode

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-8 items-start leading-none"}>
            <LawDesktopTabletTitleText pageTitle={pageTitle} />
            <LawDesktopTabletLastUpdateText lastUpdateDate={lastUpdateDate}
                                     isLegals={isLegals} />
        </div>
    );
}

// EXPORT
export default LawDesktopTabletTitle