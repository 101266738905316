import LawDesktopTabletSectionCoreText from "../lawScreens/LawDesktopTabletSectionCoreText";
import LawDesktopTabletBulletSectionText from "../lawScreens/LawDesktopTabletBulletSectionText";
import {kLegalTexts} from "../../../constants/texts";

function LegalsDesktopTabletHostText() {
    // Used in LegalsDesktopTabletContent to display
    // legal text about app host

    // BUILD
    return (
        <div className={"flex flex-col items-start justify-start space-y-8"}>
            <LawDesktopTabletSectionCoreText text={kLegalTexts.hostOne} />
            <LawDesktopTabletBulletSectionText text={kLegalTexts.hostTwo} />
            <LawDesktopTabletBulletSectionText text={kLegalTexts.hostThree} />
            <LawDesktopTabletBulletSectionText text={kLegalTexts.hostFour} />
        </div>
    );
}

// EXPORT
export default LegalsDesktopTabletHostText