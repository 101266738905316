
function SystemVerticalMiniDivider() {
    // Used through the app to display a vertical mini divider
    // to separate two pieces of information

    // BUILD
    return (
        <span className={"mb-4 text-greyLight9"}>
            |
        </span>
    );
}

// EXPORT
export default SystemVerticalMiniDivider