import AboutDesktopTabletSectionLayout from "../../layouts/aboutScreen/AboutDesktopTabletSectionLayout";
import AboutDesktopTabletSectionCoreText from "../../texts/aboutScreen/AboutDesktopTabletSectionCoreText";
import AboutDesktopTabletMissionValuesText from "../../texts/aboutScreen/AboutDesktopTabletMissionValuesText";
import AboutDesktopTabletBulletSectionText from "../../texts/aboutScreen/AboutDesktopTabletBulletSectionText";
import AboutDesktopTabletMissionConclusionText from "../../texts/aboutScreen/AboutDesktopTabletMissionConclusionText";
import AboutImage from "../../images/aboutScreen/AboutImage";
import {kAboutMissionTexts} from "../../../constants/texts";

function AboutDesktopTabletMissionSection({goToMethod}) {
    // Used in AboutDesktopTabletContent to display mission related content
    // in desktop / tablet mode

    // BUILD
    return(
        <AboutDesktopTabletSectionLayout>
            <AboutImage imageUrl={'/images/aboutScreen/mountain.png'} />
            <AboutDesktopTabletSectionCoreText text={kAboutMissionTexts.intro} />
            <AboutDesktopTabletMissionValuesText />
            <div className={"w-full flex flex-col items-start justify-start space-y-8"}>
                <AboutDesktopTabletSectionCoreText text={kAboutMissionTexts.mission} />
                <AboutDesktopTabletBulletSectionText text={kAboutMissionTexts.missionOne} />
                <AboutDesktopTabletBulletSectionText text={kAboutMissionTexts.missionTwo} />
                <AboutDesktopTabletBulletSectionText text={kAboutMissionTexts.missionThree} />
            </div>
            <AboutDesktopTabletMissionConclusionText goToMethod={goToMethod} />
        </AboutDesktopTabletSectionLayout>
    );
}

// EXPORT
export default AboutDesktopTabletMissionSection